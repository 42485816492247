import React, { useState } from 'react';
import {
  Button, Divider, Form, Message, Segment, TextArea,
} from 'semantic-ui-react';

import { CreateContactApi } from './api';

export function ContactUs(props) {
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);

  async function submitContactRequest() {
    setIsLoading(true);
    const response = await CreateContactApi({ message });
    if (response && response.error) {
      setError(response.error);
    } else {
      setError('');
      setMessage('');
      setShowSuccess(true);
    }
    setIsLoading(false);
  }

  return (
    <Segment padded="very" raised>
      <Form align="center" loading={isLoading} error={error.length > 0}>
        <Message visible align="left" size="small" error>
          {error}
        </Message>

        {showSuccess && (
          <Message
            size="small"
            align="left"
            icon="mail"
            header=" Thanks! We will get in touch with you as soon as possible."
            content=" "
          />
        )}
        <Message visible size="small" align="left" info>
          Please include any relevant details (student and/or tutor name, invoice number, etc.)
        </Message>
        <TextArea
          autoHeight
          autoFocus
          placeholder="Tell us more"
          style={{ minHeight: 100 }}
          onChange={(e, data) => setMessage(data.value)}
          value={message}
        />

        <Divider hidden />
        <Button
          id="submit-contact-request"
          primary
          disabled={message.length < 5}
          type="submit"
          onClick={() => {
            submitContactRequest();
          }}
        >
          Contact Zinc
        </Button>
      </Form>
    </Segment>
  );
}

export default ContactUs;
