import React, {
  useState, useEffect, useCallback, useRef,
} from 'react';
import PropTypes from 'prop-types';

import { withTutorshipUpdateContext } from './tutorshipUpdateContext';
import { withAsync } from '../common/withAsync';
import { createGrowthTrackerScore } from './api';
import { TestShape } from './shapes';
import ScoreForm from './score_form/scoreForm';

const AddScoreFormContainer = ({
  test, growthTrackerId, invoke, data, editMode, updateTutorshipWithMessage, closeModal,
}) => {
  const scoreContainer = useRef({});
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(
    () => {
      if (data && data.success) {
        setErrorMessage('');
        closeModal();
        updateTutorshipWithMessage('Growth tracker score added successfully.');
      } else {
        setErrorMessage(data.error);
      }
    },
    [closeModal, data, editMode, updateTutorshipWithMessage],
  );

  const updateScore = useCallback((updatedScore) => {
    scoreContainer.current = { ...scoreContainer.current, ...updatedScore };
  }, [scoreContainer]);

  const submitAfterValidation = () => {
    invoke({
      ...scoreContainer.current,
      growthTrackerId,
    });
  };

  return (
    <ScoreForm
      errorMessage={errorMessage}
      updateScore={updateScore}
      editMode={editMode}
      closeModal={closeModal}
      submitAfterValidation={submitAfterValidation}
      test={test}
    />
  );
};

AddScoreFormContainer.defaultProps = {
  data: {},
  editMode: false,
};

AddScoreFormContainer.propTypes = {
  test: PropTypes.shape(TestShape).isRequired,
  growthTrackerId: PropTypes.number.isRequired,
  invoke: PropTypes.func.isRequired,
  data: PropTypes.shape({
    success: PropTypes.string,
    error: PropTypes.string,
  }),
  editMode: PropTypes.bool,
  updateTutorshipWithMessage: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default withTutorshipUpdateContext(withAsync(AddScoreFormContainer,
  createGrowthTrackerScore,
  false));
