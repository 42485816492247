import React, { useState } from 'react';
import {
  Button, Container, Divider, Header, Step,
} from 'semantic-ui-react';

import { BillingPolicyContent, CancellationPolicyContent } from '../messages';

import { ExternalLink } from '../helper';

export function BillingCancellationForOnboarding(props) {
  const [currentStepName, setCurrentStepName] = useState('summary');

  const steps = [
    {
      index: 0,
      name: 'summary',
      text: 'Summary',
    },
    {
      index: 1,
      name: 'billing',
      text: 'Billing Policy',
    },
    {
      index: 2,
      name: 'cancellation',
      text: 'Cancellation Policy',
    },
    {
      index: 3,
      name: 'confirm',
      text: 'Confirm',
    },
  ];

  const currentStepIndex = steps.find(step => step.name === currentStepName).index;

  const Steps = () => (
    <Step.Group ordered size="mini">
      {steps.map(step => (
        <Step
          key={step.name}
          completed={step.index < currentStepIndex}
          active={step.index === currentStepIndex}
          disabled={step.index > currentStepIndex}
        >
          <Step.Content>
            <Step.Title>{step.text}</Step.Title>
          </Step.Content>
        </Step>
      ))}
    </Step.Group>
  );

  const Summary = () => (
    <Container textAlign="center">
      Here are a couple of policies we need you to know about.
      <Divider hidden />
      <Button id="summary-policy-accept" primary onClick={() => setCurrentStepName('billing')}>
        Got It
      </Button>
    </Container>
  );

  const BillingPolicy = () => (
    <Container textAlign="justified">
      <BillingPolicyContent />
      <Container textAlign="center">
        <Button
          id="billing-policy-accept"
          primary
          onClick={() => setCurrentStepName('cancellation')}
        >
          Got It
        </Button>
      </Container>
    </Container>
  );

  const CancellationPolicy = () => (
    <Container textAlign="justified">
      <CancellationPolicyContent />
      <Container textAlign="center">
        <Button
          id="cancellation-policy-accept"
          primary
          onClick={() => setCurrentStepName('confirm')}
        >
          Got It
        </Button>
      </Container>
    </Container>
  );

  const AgreeToPolicy = props => (
    <>
      <strong>We will send a copy of this Billing and Cancellation Policy to your email.</strong>
      <Divider hidden />
      <Header as="h3">
        By clicking "I Accept" you accept the
        {' '}
        <ExternalLink
          link="https://www.zinc.nyc/privacy-policy"
          text="Privacy Policy"
          ariaLabel="Zinc's Privacy Policy"
          type="privacy-policy"
        />
        {' '}
        and
        {' '}
        <ExternalLink
          link="https://www.zinc.nyc/terms-of-service"
          text="Terms of Service"
          ariaLabel="Zinc's Terms of Service"
          type="terms-of-service"
        />
        .
      </Header>

      <Divider hidden />
      <Button primary id="all-policy-accept" onClick={props.acceptPolicy}>
        I Accept
      </Button>
    </>
  );

  return (
    <>
      <Steps />

      <Divider hidden />
      {currentStepName === 'summary' && <Summary />}
      {currentStepName === 'billing' && <BillingPolicy />}
      {currentStepName === 'cancellation' && <CancellationPolicy />}
      {currentStepName === 'confirm' && <AgreeToPolicy acceptPolicy={props.onClick} />}
    </>
  );
}
