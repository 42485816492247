import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from 'semantic-ui-react';

const ButtonStyle = {
  float: 'right',
};

const ExportScores = ({ tutorshipId, customerId }) => (
  <Button
    style={ButtonStyle}
    icon
    onClick={() => {
      window.open(`/customers/${customerId}/tutorships/${tutorshipId}/growth_tracker_scores.csv`, '_self', false);
    }}
    primary
  >
    <Icon name="download" />
    Export Scores
  </Button>
);

ExportScores.propTypes = {
  tutorshipId: PropTypes.string.isRequired,
  customerId: PropTypes.string.isRequired,
};


export default ExportScores;
