import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Confirm } from 'semantic-ui-react';

function ButtonWithConfirmation({ onClick, text }) {
  const [open, setOpen] = useState(false);

  const openDialog = () => setOpen(true);
  const closeDialog = () => setOpen(false);
  const confirmAction = () => {
    onClick();
    closeDialog();
  };

  return (
    <React.Fragment>
      <Button negative onClick={openDialog}>{text}</Button>
      <Confirm
        open={open}
        onCancel={closeDialog}
        onConfirm={confirmAction}
      />
    </React.Fragment>
  );
}

ButtonWithConfirmation.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
};

export default ButtonWithConfirmation;
