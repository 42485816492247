import React, { useEffect } from 'react';

import { withAsync } from '../common/withAsync';

import { AddCreditCardApi } from './api';
import { CreditCardForm } from './form';

export const NewFormComponent = ({
  invoke, close, changeSuccessful, data,
}) => {
  useEffect(() => {
    if (data && data.success) {
      changeSuccessful();
    }
  }, [changeSuccessful, data]);

  const update = (params) => {
    invoke(params);
  };

  return (<CreditCardForm update={update} close={close} />);
};

export const NewForm = withAsync(NewFormComponent, AddCreditCardApi, false);
