import React from 'react';
import { Message, Icon } from 'semantic-ui-react';

import { SelectedBillingMenuItem } from './menu';

export const PendingConfirmationMessage = ({ paymentInfo, paymentType }) => {
  if (paymentInfo.billing_type === paymentType) {
    return <></>;
  }

  const paymentTypeText = SelectedBillingMenuItem(paymentType);
  return (
    <Message visible info>
      <Icon name="exclamation" color="yellow" />
      Confirm
      <strong>
        {' '}
        {paymentTypeText}
        {' '}
      </strong>
      as your active payment method
    </Message>
  );
};
