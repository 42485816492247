import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { App } from './app';
import RailsProps, { getRailsProps } from './util/propsContext';

const rootElement = document.getElementById('root');

ReactDOM.render(
  <RailsProps.Provider value={getRailsProps(rootElement)}>
    <Router>
      <App />
    </Router>
  </RailsProps.Provider>,
  rootElement,
);
