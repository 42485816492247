import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';

import { SubscoreShape, TestShape } from './shapes';

const SubscoreCells = ({
  test, subscores,
}) => test.subscores.map((subscore) => {
  const scoreObj = subscores
    .filter(sub => sub.growth_tracker_test_subscore.id === subscore.id)[0];
  const scoreVal = scoreObj ? scoreObj.score : '';
  return (
    <Table.Cell key={`table-cell-${subscore.id}`}>
      {scoreVal}
    </Table.Cell>
  );
});

SubscoreCells.propTypes = {
  test: PropTypes.shape(TestShape).isRequired,
  subscores: PropTypes.arrayOf(PropTypes.shape(SubscoreShape)).isRequired,
};

export default SubscoreCells;
