import React from 'react';
import PropTypes from 'prop-types';
import { Table, Dropdown } from 'semantic-ui-react';

import EditScoreModal from './editScoreModal';
import MarkAsBaselineScore from './markAsBaselineScore';
import DeleteGrowthTrackerScore from './deleteGrowthTrackerScore';
import { ScoreShape, TestShape } from './shapes';
import { GROWTH_TRACKER_BASELINE_SCORE_TYPE } from './constants';

const ScoreActionsDropdown = ({
  test, score, growthTrackerId, deleteScore, baselineScoreSection, rowSpan,
}) => {
  if (baselineScoreSection) {
    return <React.Fragment />;
  }

  return (
    <Table.Cell rowSpan={rowSpan} width="2">
      <Dropdown text="Actions" closeOnChange closeOnEscape>
        <Dropdown.Menu>
          <EditScoreModal test={test} growthTrackerId={growthTrackerId} score={score} />
          <DeleteGrowthTrackerScore onClick={() => { deleteScore(score.id); }} />
          <MarkAsBaselineScore
            scoreId={score.id}
            scoreType={GROWTH_TRACKER_BASELINE_SCORE_TYPE}
            growthTrackerId={growthTrackerId}
            baseline={score.baseline}
          />
        </Dropdown.Menu>
      </Dropdown>
    </Table.Cell>
  );
};


ScoreActionsDropdown.propTypes = {
  rowSpan: PropTypes.number.isRequired,
  growthTrackerId: PropTypes.number.isRequired,
  test: PropTypes.shape(TestShape).isRequired,
  score: PropTypes.shape(ScoreShape).isRequired,
  deleteScore: PropTypes.func.isRequired,
  baselineScoreSection: PropTypes.bool.isRequired,
};

export default ScoreActionsDropdown;
