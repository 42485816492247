import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
} from 'semantic-ui-react';

import RailsProps from '../../util/propsContext';
import { tutorshipShape } from './shapes';
import { ADMIN_USER } from './constants';
import RequestInactivation from './requestInactivation';
import BackLink from './backLink';

const QuickLinks = ({ tutorship, customerId, tutorshipId }) => (
  <Card>
    <Card.Content>
      <Card.Header>Quick Links</Card.Header>
      <RailsProps.Consumer>
        {(railsProps) => {
          const { role } = railsProps.account_props;
          return (
            <Card.Description>
              <BackLink />
              {role === ADMIN_USER && (
              <React.Fragment>
                <br />
                <a href={`/customers/${customerId}/tutorships/${tutorshipId}/edit`}>Edit Tutorship</a>
              </React.Fragment>
              )}
              <br />
              <RequestInactivation
                requested={tutorship.inactivation_requested}
                customerId={customerId}
                tutorshipId={tutorshipId}
                status={tutorship.status}
              />
            </Card.Description>
          );
        }}
      </RailsProps.Consumer>
    </Card.Content>
  </Card>
);

QuickLinks.propTypes = {
  tutorship: PropTypes.shape(tutorshipShape).isRequired,
  customerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  tutorshipId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default QuickLinks;
