import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Message } from 'semantic-ui-react';
import { HorizontallyAlignedLogo } from '../logo';
import LayoutContext from './layoutContext';

const DashboardStyle = {
  margin: '10px auto',
  padding: '0px 20px',
  width: '1150px',
};

function ContentLayout({ children }) {
  const [notification, setNotification] = useState('');

  const notify = useCallback((message) => {
    setNotification(message);
    setTimeout(() => {
      setNotification('');
    }, 2000);
  }, []);

  return (
    <Grid style={DashboardStyle}>
      <Grid.Row stretched>
        <Grid.Column
          width={16}
          textAlign="left"
          floated="left"
          style={{ marginBottom: '10px' }}
        >
          <HorizontallyAlignedLogo centered={false} />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row stretched>
        <Grid.Column
          stretched
          width={16}
        >
          {notification && (
            <Message positive>
              <p data-testid="content-layout-message">
                {notification}
              </p>
            </Message>
          )}
          <LayoutContext.Provider
            value={{
              notify,
            }}
          >
            {children}
          </LayoutContext.Provider>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

ContentLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ContentLayout;
