import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { withAsync } from '../common/withAsync';
import { ProfileForm, ProfileShape } from './profileForm';

import { FetchPersonProfile } from './api';

function PersonalProfileComponent({ data, onSuccess, isOnboardingStep }) {
  const memoizedOnSucess = useCallback(onSuccess, []);
  return (
    <ProfileForm
      profile={{ ...data }}
      isOnboardingStep={isOnboardingStep}
      onSuccess={memoizedOnSucess}
    />
  );
}

PersonalProfileComponent.defaultProps = {
  onSuccess: () => {},
  isOnboardingStep: false,
};

PersonalProfileComponent.propTypes = {
  data: ProfileShape.isRequired,
  onSuccess: PropTypes.func,
  isOnboardingStep: PropTypes.bool,
};

export const PersonalProfile = withAsync(PersonalProfileComponent, FetchPersonProfile, true);
