import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'semantic-ui-react';

export function CreditCardSelection({ list, selected, setSelected }) {
  const [currentSelected, setCurrentSelected] = useState(0);

  useEffect(() => {
    setCurrentSelected(selected);
  }, [selected]);

  const handleChange = (e, { value }) => {
    setCurrentSelected(value);
    setSelected(value);
  };

  if (list.length === 0) return <>No Credit Card Present</>;

  const options = list.map(creditCard => ({
    key: creditCard.id,
    value: creditCard.id,
    text: creditCard.card_number,
  }));

  return (
    <Select
      onChange={handleChange}
      options={options}
      value={currentSelected}
    />
  );
}

export const CardShape = PropTypes.shape({
  id: PropTypes.number,
  card_number: PropTypes.string,
});

CreditCardSelection.propTypes = {
  list: PropTypes.arrayOf(CardShape).isRequired,
  selected: PropTypes.number.isRequired,
  setSelected: PropTypes.func.isRequired,
};
