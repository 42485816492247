import React from 'react';
import { Divider, Grid, Header } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import { AllTutorships } from '../tutorships/all';
import { AccountBalance } from './accountBalance';
import { AccountStatement } from './accountStatement';
import { DownloadAccountStatement } from './downloadAccountStatement';

export function StudentSummary({ name, id, outstanding_balance }) {
  return (
    <>
      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column textAlign="left">
            <Header as="h1">{name}</Header>
          </Grid.Column>
          <Grid.Column textAlign="right">
            <AccountBalance outstanding_balance={outstanding_balance} />
          </Grid.Column>
        </Grid.Row>
        <Divider />
        <Grid.Row columns={1}>
          <Grid.Column textAlign="right">
            <DownloadAccountStatement studentId={id} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Divider hidden />
      <AccountStatement balance={outstanding_balance} studentId={id} />
      <Divider hidden />
      <AllTutorships studentId={id} />
    </>
  );
}

export const StudentSummaryShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  outstanding_balance: PropTypes.number.isRequired,
});

StudentSummary.propTypes = StudentSummaryShape;
