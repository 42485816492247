import { instance } from '../../config/api';

const onboardingStepUrl = '/people/onboarding_step.json';
const submitCurrentStepUrl = '/people/next_step.json';
const submitRegionStepUrl = '/region.json';

export const FetchCurrentOnboardingStep = async () => {
  try {
    const result = await instance.get(onboardingStepUrl, {});
    if (result.status === 200) {
      return result.data;
    }
  } catch (error) {
    return { error: error.message };
  }
};

export const SubmitRegion = async (region) => {
  try {
    const result = await instance.post(submitRegionStepUrl, {
      region,
    });
    if (result.status === 204) {
      return { success: true };
    }
  } catch (error) {
    return { error: error.message };
  }
};

export const SubmitCurrentStep = async (onboardingStep) => {
  try {
    const result = await instance.post(submitCurrentStepUrl, {
      person: { onboarding_step: onboardingStep },
    });
    if (result.status === 200) {
      return result.data;
    }
    if (result.status === 204) {
      return { success: true };
    }
  } catch (error) {
    return { error: error.message };
  }
};
