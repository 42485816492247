import React from 'react';
import { Link } from 'react-router-dom';

import { Button, Divider, Header } from 'semantic-ui-react';

import { ZINC_EMAIL_ADDRESS } from './constants';
import { ExternalLink } from './helper';

export const Welcome = () => (
  <>
    <Header as="h1"> Welcome to Zinc </Header>
    <strong>
      <i>where we’re all about meaning and growth.</i>
    </strong>
    <Divider hidden />
    Thanks in advance for setting up this account. It should take about three minutes!
  </>
);

export const ContactUs = () => (
  <p>
    Contact us at
    {' '}
    <ExternalLink
      link={`mailto:${ZINC_EMAIL_ADDRESS}`}
      text={ZINC_EMAIL_ADDRESS}
      ariaLabel="Zinc's Email"
      type="mail-zinc"
    />
    {' '}
    with any questions at all
  </p>
);

export const RegistrationComplete = props => (
  <>
    <Header>You're all set!</Header>

    <Divider hidden />
    <Button size="large" as={Link} to="/dashboard/" primary>
      My Dashboard
    </Button>
  </>
);

export const BillingPolicyContent = () => (
  <>
    <Header>Billing</Header>
    <p>
      Zinc bills twice a month, on the 1st and the 15th. You can pay by credit card/debit card (Visa
      or MasterCard only), check, or wire transfer.
    </p>
    <p>
      Zinc requires credit or debit card information from
      {' '}
      <strong>all clients</strong>
      {' '}
for security
      purposes. Zinc reserves the right to use the card toward payment of invoices that are more
      than 30 days past the billing date, and can add a late fee of 3% for overdue invoices. Failure
      to provide valid card information may result in suspension of tutoring.
    </p>
  </>
);
export const CancellationPolicyContent = () => (
  <>
    <Header> Cancellation </Header>
    <p>Zinc has a 24-hour cancelation policy.</p>
    <p>
      If you give the tutor 24 hours’ notice, you may cancel or reschedule without being billed. We
      do not bill for cancellations due to sudden illnesses or other legitimate, unforeseen
      emergencies. No-shows and sessions cancelled within 24 hours for avoidable and/or non-vital
      reasons will be billed at the regular rate.
    </p>
  </>
);

export const PaymentSchedule = () => (
  <>
    Zinc bills on the 1st and the 15th of every month. For security purposes, we require a
    {' '}
    <strong>valid Visa or Mastercard;</strong>
    {' '}
however,
    {' '}
    <strong>you can pay by any of the following methods:</strong>
  </>
);
export const WireTransferPaymentInstructions = () => (
  <>
    You will receive invoices and Zinc’s wire transfer details via email. You can also view invoices
    on the Zinc Dashboard.
  </>
);

export const CheckPaymentInstructions = () => (
  <>
    <p>
      You will receive invoices via email, and can also view them in the Zinc Dashboard. Please pay
      all invoices within 30 days of receipt.
    </p>
    <p>Please mail checks to:</p>
    <pre>
      <strong>
        Zinc Educational Services
        {'\n'}
        41 Union Square West, Suite 1125,
        {'\n'}
        New York, NY 10003
      </strong>
    </pre>
    <p>
      Please make checks out to
      {' '}
      <strong>Zinc Educational Services</strong>
      {' '}
and include the
      {' '}
      <strong>student’s name </strong>
      in the check’s memo.
    </p>
  </>
);

const EmailBillingContact = () => (
  <ExternalLink
    link="mailto:ninette@zinc.nyc"
    text="ninette@zinc.nyc"
    ariaLabel="Ninette’s Email"
    type="mail-zinc"
  />
);

const ZincPhoneNumber = () => (
  <ExternalLink
    type="call-zinc"
    link="tel:+12129243040"
    text="+1.212.924.3040"
    ariaLabel="Ninette’s Phone Number"
  />
);

export const NoBackupCreditCardProvided = () => (
  <p>
    <strong>
      Zinc requires a valid Visa or Mastercard for security purposes. Please enter one or contact
      Ninette, Zinc’s Business Manager, at
      {' '}
      <EmailBillingContact />
      {' '}
, or
      {' '}
      <ZincPhoneNumber />
    </strong>
  </p>
);

export const AskForBackupCreditCard = () => (
  <p>
    Before tutoring can begin, Zinc requires a valid Visa or Mastercard for security purposes. We
    will only bill this credit card if your balance is more than a month overdue, and we will make
    every effort to notify you before doing so.
  </p>
);
