import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Confirm } from 'semantic-ui-react';


import { createGrowthTrackerBaselineScore } from './api';
import { withAsync } from '../common/withAsync';
import { withTutorshipUpdateContext } from './tutorshipUpdateContext';

function MarkAsBaselineScore({
  scoreId, scoreType, data, updateTutorshipWithMessage, growthTrackerId, invoke, baseline,
}) {
  const [open, setOpen] = useState(false);
  useEffect(
    () => {
      if (data && data.success) {
        updateTutorshipWithMessage('Score successfully marked as baseline');
      }
    },
    [data, updateTutorshipWithMessage],
  );

  const openDialog = () => setOpen(true);
  const closeDialog = () => setOpen(false);
  const confirmAction = () => {
    invoke({ scoreId, scoreType, growthTrackerId });
    closeDialog();
  };

  if (baseline) {
    return <React.Fragment />;
  }

  return (
    <React.Fragment>
      <Dropdown.Item onClick={openDialog} text="Mark as baseline" />
      <Confirm
        open={open}
        onCancel={closeDialog}
        onConfirm={confirmAction}
      />
    </React.Fragment>
  );
}

MarkAsBaselineScore.defaultProps = {
  data: {},
  baseline: false,
};

MarkAsBaselineScore.propTypes = {
  scoreId: PropTypes.number.isRequired,
  scoreType: PropTypes.string.isRequired,
  data: PropTypes.shape({
    success: PropTypes.string,
    error: PropTypes.string,
  }),
  invoke: PropTypes.func.isRequired,
  growthTrackerId: PropTypes.number.isRequired,
  updateTutorshipWithMessage: PropTypes.func.isRequired,
  baseline: PropTypes.bool,
};

export default withTutorshipUpdateContext(withAsync(MarkAsBaselineScore,
  createGrowthTrackerBaselineScore, false));
