import React, { useState, useEffect } from 'react';

import {
  Button, Table, Icon, Header, Label,
} from 'semantic-ui-react';
import { NewForm } from './newForm';
import { CardRow } from './row';

import { SetDefaultCreditCardApi, DeleteCreditCardApi } from './api';

export const CreditCardIcon = () => <Icon name="credit card outline" />;

const AddCreditCardButton = ({ onClick }) => (
  <Button primary size="mini" float="right" onClick={onClick}>
    <CreditCardIcon />
    Add New
  </Button>
);

export function CreditCardList({ hideNewCard, creditCards, reload }) {
  const [cards, setCards] = useState([]);

  useEffect(
    () => {
      setCards(creditCards);
    },
    [creditCards],
  );

  const [showNewCard, setShowNewCard] = useState(true);

  const setCardAsDefault = async (cardId) => {
    await SetDefaultCreditCardApi({ cardId });
    reload({ message: 'Card set as default successfully' });
  };

  const deleteCard = async (cardId) => {
    await DeleteCreditCardApi({ cardId });
    reload({ message: 'Card was deleted successfully' });
  };

  useEffect(
    () => {
      if (hideNewCard) {
        setShowNewCard(false);
      } else {
        setShowNewCard(cards.filter(card => card.expired === false).length === 0);
      }
    },
    [cards, hideNewCard],
  );

  const AddCreditCardSection = () => (showNewCard ? (
    <NewForm
      changeSuccessful={() => {
        reload({ message: 'Card was added successfully' });
        setShowNewCard(false);
      }}
      close={() => {
        setShowNewCard(false);
      }}
    />
  ) : (
    <AddCreditCardButton
      onClick={() => {
        setShowNewCard(true);
      }}
    />
  ));

  return (
    <>
      <Header as="h3">
        Credit Card
        {' '}
        <Label as="a" color="green" basic pointing="left">
          Visa & Mastercard
        </Label>
      </Header>

      {cards.length !== 0 && (
        <Table padded size="small" celled={false} compact>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Card Number</Table.HeaderCell>
              <Table.HeaderCell>Card Type</Table.HeaderCell>
              <Table.HeaderCell>Expiration</Table.HeaderCell>
              <Table.HeaderCell>Default Card</Table.HeaderCell>
              <Table.HeaderCell>Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {cards.map(card => (
              <CardRow
                key={card.id}
                card={card}
                setCardAsDefault={() => setCardAsDefault(card.id)}
                deleteCard={() => deleteCard(card.id)}
                reload={reload}
              />
            ))}
          </Table.Body>
        </Table>
      )}

      <AddCreditCardSection />
    </>
  );
}
