import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withAsync } from '../common/withAsync';
import { StudentTabsWithRouter } from './studentTabs';
import { StudentSummaryShape } from './studentSummary';
import { ListAllPayments } from '../payment/api';

export function StudentsSummaryComponent({ data }) {
  const [students, setStudents] = useState([]);

  useEffect(() => {
    setStudents(data.students);
  }, [data]);

  if (students.length === 0) {
    return <></>;
  }

  return <StudentTabsWithRouter students={students} />;
}

StudentsSummaryComponent.propTypes = {
  data: PropTypes.shape({ students: PropTypes.arrayOf(StudentSummaryShape) }),
};

StudentsSummaryComponent.defaultProps = {
  data: {
    students: [],
  },
};

export const StudentsSummary = withAsync(StudentsSummaryComponent,
  ListAllPayments,
  true);
