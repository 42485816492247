import axios from 'axios';
import httpAdapter from 'axios/lib/adapters/http';

const offlineAdapter = () => Promise.reject(new Error('You are currently offline'));

export const HOST = process.env.REACT_APP_API_HOST
  ? process.env.REACT_APP_API_HOST
  : 'http://localhost:3000';

axios.defaults.host = HOST;
axios.defaults.adapter = httpAdapter;

export const instance = axios.create({
  baseURL: `${HOST}/api`,
  timeout: 30000,
  withCredentials: true,
});

instance.interceptors.request.use((config) => {
  const newConfig = { ...config };
  if (!navigator.onLine) {
    newConfig.adapter = offlineAdapter;
  }
  return newConfig;
});

instance.interceptors.response.use(
  response => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      window.location = '/dashboard';
    }
    return Promise.reject(error);
  },
);
