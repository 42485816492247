import React from 'react';
import { Button } from 'semantic-ui-react';
import './back_link.scss';

const BackLink = () => (
  <Button
    className="back-link"
    primary
    onClick={() => {
      window.history.back();
    }}
  >
    Go Back
  </Button>
);

export default BackLink;
