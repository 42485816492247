import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'semantic-ui-react';

import { tutorshipShape } from './shapes';
import { STUDENT_CUSTOMER } from './constants';

const CustomerDetails = ({ tutorship, customerId }) => {
  if (Object.keys(tutorship).length === 0) {
    return <React.Fragment />;
  }

  return (
    <Card>
      <Card.Content>
        <Card.Header>{tutorship.customer_type === STUDENT_CUSTOMER ? 'Student' : 'Class'}</Card.Header>
        <Card.Description>
          <a href={`/customers/${customerId}`}>
            {tutorship.customer.name}
          </a>
          <br />
          {`Subject: ${tutorship.subject_names}`}
        </Card.Description>
      </Card.Content>
    </Card>
  );
};

CustomerDetails.propTypes = {
  tutorship: PropTypes.shape(tutorshipShape).isRequired,
  customerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default CustomerDetails;
