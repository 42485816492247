import { instance } from '../../config/api';

const paymentMethodUrl = '/payment_configuration.json';

export const FetchPaymentMethodApi = async () => {
  try {
    const result = await instance.get(paymentMethodUrl, {});
    if (result.status === 200) {
      return result.data;
    }
  } catch (error) {
    return { error };
  }
};

export const UpdatePaymentMethodApi = async ({ data }) => {
  try {
    const result = await instance.put(paymentMethodUrl, { payment_configuration: data });
    if (result.status === 204) {
      return { success: true };
    }
    if (result.status === 200) {
      return result.data;
    }
  } catch (error) {
    return { error };
  }
};
