import React from 'react';
import PropTypes from 'prop-types';

import { Header } from 'semantic-ui-react';

export function AuthorizationMessage({ numberOfPaymentRequests }) {
  const authorizationMessage = numberOfPaymentRequests > 0 && (
    <>
      {numberOfPaymentRequests === 1 ? (
        <>I authorize Zinc to charge the selected Credit Card</>
      ) : (
        <>I authorize Zinc to charge the selected Credit Cards</>
      )}
    </>
  );

  return <Header as="h3">{authorizationMessage}</Header>;
}

AuthorizationMessage.propTypes = {
  numberOfPaymentRequests: PropTypes.number.isRequired,
};
