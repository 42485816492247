import React from 'react';
import { FlashMessage } from './flashMessage';
import RailsProps from '../util/propsContext';


export default function FlashMessages() {
  return (
    <RailsProps.Consumer>
      {
        (railsProps) => {
          const flashMessages = railsProps.flash_messages;
          if (flashMessages) {
            return Object.keys(flashMessages)
              .filter(type => type === 'alert' || type === 'notice')
              .map(type => (
                <FlashMessage key={type} type={type} message={flashMessages[type]} />
              ));
          }
          return <></>;
        }
      }
    </RailsProps.Consumer>
  );
}
