import React from 'react';

import { Table, Icon, Button } from 'semantic-ui-react';

const EditCardIcon = ({ setEditView }) => (
  <Icon link color="blue" name="pencil" onClick={setEditView} />
);
const DeleteCardIcon = ({ deleteCard }) => (
  <Icon link color="red" name="trash" onClick={deleteCard} />
);

const SetDefaultCard = ({ card, setCardAsDefault }) => (card && card.is_default_card ? (
  <Button as="span" size="mini" disabled color="green" basic style={{ boxShadow: 'initial' }}>
      Default Card
  </Button>
) : (
  <Button as="a" size="mini" basic color="green" style={{ boxShadow: 'initial' }} onClick={setCardAsDefault}>
      Set as Default
  </Button>
));

export const ShowCard = ({
  card, setCardAsDefault, deleteCard, setEditView,
}) => (
  <Table.Row warning={!card.expired && card.expiring_soon} negative={card.expired}>
    <Table.Cell>{card.card_number}</Table.Cell>
    <Table.Cell>{card.type}</Table.Cell>
    <Table.Cell>
      {card.expiration_month}
/
      {card.expiration_year}
    </Table.Cell>
    <Table.Cell>
      <SetDefaultCard card={card} setCardAsDefault={setCardAsDefault} />
    </Table.Cell>
    <Table.Cell>
      {card.can_update ? <EditCardIcon setEditView={setEditView} /> : <Icon />}
      &nbsp;&nbsp;&nbsp;
      {card.can_delete ? <DeleteCardIcon deleteCard={deleteCard} /> : <Icon />}
    </Table.Cell>
  </Table.Row>
);
