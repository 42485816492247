import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Modal } from 'semantic-ui-react';

import EditScoreFormContainer from './editScoreFormContainer';
import { ScoreShape, TestShape } from './shapes';

const EditScoreModal = ({ test, growthTrackerId, score }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Modal
      trigger={<Dropdown.Item onClick={handleOpen}>Edit</Dropdown.Item>}
      open={open}
      onClose={handleClose}
      closeOnDimmerClick={false}
    >
      <Modal.Header>Update test score</Modal.Header>
      <Modal.Content>
        <EditScoreFormContainer
          test={test}
          growthTrackerId={growthTrackerId}
          score={score}
          editMode
          closeModal={handleClose}
        />
      </Modal.Content>
    </Modal>
  );
};

EditScoreModal.propTypes = {
  test: PropTypes.shape(TestShape).isRequired,
  score: PropTypes.shape(ScoreShape).isRequired,
  growthTrackerId: PropTypes.number.isRequired,
};

export default EditScoreModal;
