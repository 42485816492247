import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Message } from 'semantic-ui-react';

import { withAsync } from '../common/withAsync';
import { fetchGrowthTrackerTests } from './api';
import { TestShape } from './shapes';
import GrowthTrackerForm from './growthTrackerForm';

const NewGrowthTracker = ({ tutorshipId, data }) => {
  const [testTypes, setTestTypes] = useState([]);
  useEffect(
    () => {
      if (data !== undefined) {
        setTestTypes(data);
      }
    },
    [data],
  );

  return (
    <React.Fragment>
      <Message info>
        <p>
          {'You don\'t have any trackers set up. Follow the steps below to set up a test tracker.'}
        </p>
      </Message>
      <GrowthTrackerForm
        testTypes={testTypes}
        tutorshipId={tutorshipId}
      />
    </React.Fragment>
  );
};

NewGrowthTracker.defaultProps = {
  data: [],
};

NewGrowthTracker.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape(TestShape)),
  tutorshipId: PropTypes.string.isRequired,
};

export default withAsync(NewGrowthTracker, fetchGrowthTrackerTests, true);
