import React, { useState, useEffect, useCallback } from 'react';
import {
  Table, Header, Button, Message,
} from 'semantic-ui-react';

import { PaymentRow } from './row';
import { formatAmount } from '../helper';
import { AuthorizationMessage } from './authorizationMessage';
import { CreateNewPayments } from './api';
import { withAsync } from '../common/withAsync';

export function PaymentTableComponent({
  students, creditCards, invoke, data, parentInvoke,
}) {
  const [values, setValues] = useState({});
  const [status, setStatus] = useState(null);

  useEffect(
    () => {
      const selectedValues = students.reduce((result, student) => {
        const value = {
          amount: student.paymentRequest.amount,
          creditCardId: student.paymentRequest.creditCardId,
        };
        return { ...result, [student.id]: value };
      }, {});
      setValues(selectedValues);
    },
    [students, creditCards],
  );

  useEffect(
    () => {
      if (data) {
        setStatus(data.status);
        parentInvoke();
      }
    },
    [data, parentInvoke],
  );

  const updatePaymentRequest = useCallback((id, field, value) => {
    setValues((latestValues) => {
      const newValue = { ...latestValues[id], [field]: value };
      return { ...latestValues, [id]: { ...newValue } };
    });
  }, []);

  const total = Object.values(values)
    .map(paymentRequest => parseInt(paymentRequest.amount, 10))
    .reduce((totalAmount, amount) => amount + totalAmount, 0);

  const paymentRequestsLength = Object.values(values).filter(
    paymentRequest => paymentRequest.amount > 0,
  ).length;

  const computePaymentRequests = () => {
    const paymentRequestsData = [];
    Object.entries(values).forEach(([studentId, paymentRequest]) => {
      if (paymentRequest.amount > 0) {
        paymentRequestsData.unshift({
          student_id: studentId,
          payment_profile_id: paymentRequest.creditCardId,
          amount: paymentRequest.amount,
        });
      }
    });
    return { payment_requests: paymentRequestsData };
  };

  return (
    <>
      {status === "success" && <Message visible positive content="All transactions completed successfully." />}
      {status === "failure" && <Message visible negative content="All transactions have failed. Please try again." />}
      {status === "partial_failure" && <Message visible negative content="Some transactions have failed. Please try paying the remaining amount again." />}
      <Table className="segment raised" padded="very" unstackable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Student Name</Table.HeaderCell>
            <Table.HeaderCell>Balance Amount</Table.HeaderCell>
            <Table.HeaderCell>Credit Card</Table.HeaderCell>
            <Table.HeaderCell>Enter Amount</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {students.map(student => (
            <Table.Row verticalAlign="top" key={student.id}>
              <PaymentRow
                id={student.id}
                name={student.name}
                balance={student.outstanding_balance}
                creditCardId={
                  values[student.id]
                    ? values[student.id].creditCardId
                    : student.paymentRequest.creditCardId
                }
                amount={
                  values[student.id] ? values[student.id].amount : student.paymentRequest.amount
                }
                creditCards={creditCards}
                updatePaymentRequest={updatePaymentRequest}
              />
            </Table.Row>
          ))}
          <Table.Row>
            <Table.Cell colSpan="4" textAlign="right">
              <Header as="h3">
                Total:&nbsp;
                {formatAmount(total)}
              </Header>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell colSpan="3" textAlign="center">
              <AuthorizationMessage numberOfPaymentRequests={paymentRequestsLength} />
            </Table.Cell>
            <Table.Cell textAlign="right">
              <Button
                id="confirm-payment"
                primary
                size="big"
                type="submit"
                disabled={total <= 0 || creditCards.length === 0}
                onClick={() => {
                  setStatus(null);
                  invoke({
                    data: computePaymentRequests(),
                  });
                }}
              >
                Pay
                {' '}
                {formatAmount(total)}
              </Button>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </>
  );
}

export const PaymentTable = withAsync(PaymentTableComponent, CreateNewPayments, false);
