import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'semantic-ui-react';

import { tutorshipShape } from './shapes';
import QuickLinks from './quickLinks';
import CustomerDetails from './customerDetails';

const Header = ({ tutorship, customerId, tutorshipId }) => {
  if (Object.keys(tutorship).length === 0) {
    return <React.Fragment />;
  }

  return (
    <Card.Group itemsPerRow="3">
      <CustomerDetails tutorship={tutorship} customerId={customerId} />
      <Card>
        <Card.Content>
          <Card.Header>Tutor</Card.Header>
          <Card.Description>
            <a href={`/tutors/${tutorship.tutor.id}`}>
              {tutorship.tutor.name}
            </a>
            <br />
            {`Rate: $${tutorship.tutor.rate} per hour`}
            <br />
            {`Commission: ${tutorship.tutor.commission}%`}
          </Card.Description>
        </Card.Content>
      </Card>
      <QuickLinks tutorship={tutorship} customerId={customerId} tutorshipId={tutorshipId} />
    </Card.Group>
  );
};

Header.defaultProps = {
  tutorship: {},
};

Header.propTypes = {
  tutorship: PropTypes.shape(tutorshipShape),
  customerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  tutorshipId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default Header;
