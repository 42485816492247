import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Icon, Label } from 'semantic-ui-react';

import { formatAmount } from '../helper';

export function AccountBalance({ outstanding_balance }) {
  return outstanding_balance > 0 ? (
    <>
      <Balance amount={outstanding_balance} />
      <Button id="pay-now" primary as={Link} to="/dashboard/payment">Pay Now</Button>
    </>
  ) : (
    <Credit amount={outstanding_balance} />
  );
}

function Balance({ amount }) {
  return (
    <Button as="div" labelPosition="right" size="small">
      <Button color="red" size="small">
        <Icon name="money bill alternate" />
        Balance
      </Button>
      <Label as="a" basic color="red" pointing="left">
        {formatAmount(amount)}
      </Label>
    </Button>
  );
}

function Credit({ amount }) {
  return (
    <Button as="div" labelPosition="right" size="small">
      <Button color="green" size="small">
        <Icon name="money bill alternate" />
        Credit
      </Button>
      <Label as="a" basic color="green" pointing="left">
        {formatAmount(amount === 0 ? 0 : -amount)}
      </Label>
    </Button>
  );
}
