import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Message } from 'semantic-ui-react';

import { tutorshipShape } from './shapes';
import GrowthTracker from './growthTracker';
import { STUDENT_CUSTOMER } from './constants';

const GrowthTrackerContainer = (props) => {
  const {
    tutorship, tutorshipId, notification,
  } = props;
  if (tutorship.customer_type !== STUDENT_CUSTOMER) {
    return <React.Fragment />;
  }

  return (
    <React.Fragment>
      <br />
      <Divider clearing />
      {notification && (
      <Message positive visible>
        <p>
          {notification}
        </p>
      </Message>
      )}
      <GrowthTracker
        tutorshipId={tutorshipId}
        growthTracker={tutorship.growth_tracker}
        growthTrackerAvailable={tutorship.growth_tracker_available}
      />
    </React.Fragment>
  );
};

GrowthTrackerContainer.defaultProps = {
  notification: '',
};

GrowthTrackerContainer.propTypes = {
  tutorship: PropTypes.shape(tutorshipShape).isRequired,
  tutorshipId: PropTypes.string.isRequired,
  notification: PropTypes.string,
};

export default GrowthTrackerContainer;
