import React from 'react';
import PropTypes from 'prop-types';
import { Message } from 'semantic-ui-react';

import { ScoreShape, ZincScoreShape } from './shapes';

const ScoresEmpty = ({ scores, title }) => {
  if (scores.length > 0) {
    return <></>;
  }

  return (<Message color="red" visible>{`You have not added any ${title} yet.`}</Message>);
};

ScoresEmpty.propTypes = {
  scores: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape(ZincScoreShape),
      PropTypes.shape(ScoreShape),
    ]),
  ).isRequired,
  title: PropTypes.string.isRequired,
};

export default ScoresEmpty;
