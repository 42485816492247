import React, { useState } from 'react';
import { Header, Radio } from 'semantic-ui-react';

import Students from './students';

function ListStudents() {
  const [onlyPending, setOnlyPending] = useState(true);

  const handlePendingChange = () => {
    setOnlyPending(currentVal => !currentVal);
  };

  return (
    <React.Fragment>
      <Header as="h3">Manage Growth Trackers</Header>
      <Radio label="Only Pending" toggle checked={onlyPending} onChange={handlePendingChange} />
      <Students onlyPending={onlyPending} />
    </React.Fragment>
  );
}

export default ListStudents;
