import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import { pdfAccountStatementForCustomerUrl } from './api';

export function DownloadAccountStatement({ studentId }) {
  const [downloaded, setDownloaded] = useState(false);

  async function download() {
    setDownloaded(true);
  }

  return (
    <Button
      primary
      disabled={downloaded}
      href={pdfAccountStatementForCustomerUrl(studentId)}
      target="_blank"
      onClick={download}
      id="download-account-statement"
    >
      {downloaded ? 'Account Statement Downloaded' : 'Download Account Statement'}
    </Button>
  );
}

DownloadAccountStatement.propTypes = {
  studentId: PropTypes.number.isRequired,
};
