import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form } from 'semantic-ui-react';

import getTag from '../../util/metatag';
import './request_inactivation.scss';
import { INACTIVE_STATUS, ACTIVE_STATUS } from './constants';


const RequestInactivation = ({
  status, requested, customerId, tutorshipId,
}) => {
  if (status === INACTIVE_STATUS) {
    return <React.Fragment />;
  }

  if (requested) {
    return <span>Inactivation request submitted</span>;
  }

  const csrfToken = getTag('csrf-token');

  return (
    <Form
      action={`/customers/${customerId}/tutorships/${tutorshipId}/request_inactivation`}
      method="post"
      acceptCharset="UTF-8"
      className="request-inactivation"
    >
      <input type="hidden" name="_method" value="patch" />
      <input type="hidden" name="authenticity_token" value={csrfToken} />
      <Button className="" name="commit" primary id="request_inactivation">
        Request Inactivation
      </Button>
    </Form>
  );
};


RequestInactivation.propTypes = {
  status: PropTypes.oneOf([INACTIVE_STATUS, ACTIVE_STATUS]).isRequired,
  requested: PropTypes.bool.isRequired,
  customerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  tutorshipId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default RequestInactivation;
