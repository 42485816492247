import React, { useState } from 'react';
import { Button, Form, Radio } from 'semantic-ui-react';
import { ExternalLink } from '../helper';

export function UpdateRegion(props) {
  const { onSuccess } = props;

  const [region, setRegion] = useState('');
  const [isApproved, setApproved] = useState(false);

  const PrivacyPolicyApproval = () => (
    <>
      <Form.Group style={{ margin: '0 auto', maxWidth: '600px' }}>
        <Form.Field key="eu">
          <Radio
            label="I am a citizen of European Union"
            value="eu"
            checked={region === 'eu'}
            onChange={() => {
              setRegion('eu');
              setApproved(false);
            }}
          />
        </Form.Field>

        <Form.Field key="non-eu">
          <Radio
            label="I am a not a citizen of the European Union"
            value="non-eu"
            checked={region === 'non-eu'}
            onChange={() => {
              setRegion('non-eu');
            }}
          />
        </Form.Field>
      </Form.Group>

      <EUPrivacyNotice />
    </>
  );

  const showSubmitButton = () => region !== '' && (region !== 'eu' || isApproved === true);
  const EUPrivacyNotice = () => (
    <div style={{ visibility: region !== 'eu' && 'hidden' }}>
      <p>
        If you are based in the EEA, please note that, on creation of an account with Zinc
        Educational Services, Inc., the personal data you have provided will be transferred to us in
        the USA so that we can set up your account and process any payments you make to us. Please
        note that the country to which your data is being transferred does not provide for an
        adequate level of data protection and that you have a right to withdraw your consent at any
        time. Please refer to our&nbsp;
        <ExternalLink
          link="https://www.zinc.nyc/privacy-policy"
          text="Privacy Policy"
          ariaLabel="Zinc's Privacy Policy"
          type="privacy-policy"
        />
        &nbsp; for more information about us, your rights and how we process your data.
      </p>

      <Form.Field
        control="input"
        type="checkbox"
        checked={isApproved}
        onChange={() => setApproved(!isApproved)}
        label="Please tick this box to indicate your consent to the transfer of your data"
      />
    </div>
  );

  return (
    <Form>
      <PrivacyPolicyApproval />
      <Button
        primary
        onClick={() => onSuccess(region)}
        style={{ visibility: !showSubmitButton() && 'hidden' }}
      >
        Submit
      </Button>
    </Form>
  );
}
