import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Table, Input } from 'semantic-ui-react';

import { formatAmount } from '../helper';
import { CreditCardSelection, CardShape } from './creditCardSelection';

function getBalanceText(balance, amountToCharge) {
  const amount = parseInt(amountToCharge, 10);
  const difference = balance - amount;
  if (balance === 0 && amount > 0) {
    return `Paying advance of ${formatAmount(amount)}`;
  }
  if (balance < 0 && amount > 0) {
    return `Paying extra advance of ${formatAmount(amount)}`;
  }
  if (balance > 0) {
    if (difference === 0) {
      return 'Paying the exact amount';
    }
    if (difference < 0) {
      return `Paying advance of ${formatAmount(0 - difference)}`;
    }
    return `Owes Zinc ${formatAmount(difference)}`;
  }
  return '';
}

export function PaymentRow({
  amount,
  creditCardId,
  creditCards,
  name,
  balance,
  id,
  updatePaymentRequest,
}) {
  const [amountToCharge, setAmountToCharge] = useState(0);
  const [selectedCreditCardId, setSelectedCreditCardId] = useState(0);

  useEffect(
    () => {
      setAmountToCharge(amount);
      setSelectedCreditCardId(creditCardId);
    },
    [amount, creditCardId],
  );

  return (
    <>
      <Table.Cell>{name}</Table.Cell>
      <Table.Cell>{formatAmount(balance)}</Table.Cell>
      <Table.Cell>
        {creditCards && (
          <CreditCardSelection
            list={creditCards}
            selected={selectedCreditCardId}
            setSelected={(value) => {
              updatePaymentRequest(id, 'creditCardId', value);
            }}
          />
        )}
      </Table.Cell>
      <Table.Cell>
        <Input
          fluid
          label="$"
          size="large"
          type="number"
          min="0"
          max="19999"
          onChange={(e) => {
            if (!e.target.checkValidity()) return;
            setAmountToCharge(Number(e.target.value));
            updatePaymentRequest(id, 'amount', Number(e.target.value));
          }}
          value={amountToCharge.toString()}
          autoFocus
        />
        {getBalanceText(balance, amountToCharge)}
      </Table.Cell>
    </>
  );
}

PaymentRow.propTypes = {
  amount: PropTypes.number.isRequired,
  creditCardId: PropTypes.number.isRequired,
  creditCards: PropTypes.arrayOf(CardShape).isRequired,
  name: PropTypes.string.isRequired,
  balance: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  updatePaymentRequest: PropTypes.func.isRequired,
};
