import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';

import { HOST } from '../../config/api';
import { formatAmount } from '../helper';

export const AccountStatementItem = ({
  download_path,
  type,
  number,
  id_display,
  details,
  date,
  amount,
  charge_type,
  balance_as_of_creation,
}) => (
  <Table.Row>
    <Table.Cell>
      {download_path ? (
        <a
          id={`download-statement-item-${type}-${number}`}
          target="_blank"
          rel="noopener noreferrer"
          href={HOST + download_path}
          aria-label={`${type} #${number}`}
        >
          {`${type} #${number}`}
        </a>
      ) : (
        <>{`${type} #${number}`}</>
      )}
    </Table.Cell>
    <Table.Cell>{id_display}</Table.Cell>
    <Table.Cell>{details}</Table.Cell>
    <Table.Cell>{date}</Table.Cell>
    <Table.Cell>
      {formatAmount(amount)}
      {' '}
      {charge_type === 'credit' && ' CR'}
    </Table.Cell>
    <Table.Cell>{formatAmount(balance_as_of_creation)}</Table.Cell>
  </Table.Row>
);

AccountStatementItem.defaultProps = {
  download_path: '',
};

AccountStatementItem.propTypes = {
  download_path: PropTypes.string,
  type: PropTypes.string.isRequired,
  number: PropTypes.number.isRequired,
  id_display: PropTypes.string.isRequired,
  details: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  charge_type: PropTypes.string.isRequired,
  balance_as_of_creation: PropTypes.number.isRequired,
};
