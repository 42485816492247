import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Radio } from 'semantic-ui-react';

import { withAsync } from '../common/withAsync';
import { FetchRelationships } from './api';

function RelationshipsComponent({ data, selectedId, onChange }) {
  const [selectedValue, setSelectedValue] = useState(0);

  useEffect(
    () => {
      setSelectedValue(selectedId);
    },
    [selectedId],
  );

  return (
    <Form.Group inline>
      <label htmlFor="relationship">Your relationship to this student: </label>
      {data.map(relationship => (
        <Form.Field key={relationship.id}>
          <Radio
            label={relationship.name}
            value={relationship.id}
            checked={relationship.id === selectedValue}
            onChange={onChange}
          />
        </Form.Field>
      ))}
    </Form.Group>
  );
}

RelationshipsComponent.defaultProps = {
  data: [],
};

RelationshipsComponent.propTypes = {
  data: PropTypes.shape({ id: PropTypes.number, name: PropTypes.string }),
  selectedId: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export const Relationships = withAsync(RelationshipsComponent, FetchRelationships, true);
