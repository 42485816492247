import React from 'react';

const TutorshipUpdateContext = React.createContext();

export function withTutorshipUpdateContext(WrappedComponent) {
  function Wrapper(props) {
    return (
      <TutorshipUpdateContext.Consumer>
        {({ updateTutorshipWithMessage, tutorshipId, customerId }) => (
          <WrappedComponent
            {...props}
            updateTutorshipWithMessage={updateTutorshipWithMessage}
            tutorshipId={tutorshipId}
            customerId={customerId}
          />
        )}
      </TutorshipUpdateContext.Consumer>
    );
  }

  return Wrapper;
}


export default TutorshipUpdateContext;
