import React from 'react';
import PropTypes from 'prop-types';
import { Message } from 'semantic-ui-react';

import { tutorshipShape } from './shapes';
import { INACTIVE_STATUS } from './constants';

const InactiveTutorshipMessage = ({ tutorship }) => {
  if (tutorship && tutorship.status === INACTIVE_STATUS) {
    return (
      <Message negative visible>
        <p>
          The tutorship is inactive.
        </p>
      </Message>
    );
  }
  return <React.Fragment />;
};

InactiveTutorshipMessage.propTypes = {
  tutorship: PropTypes.shape(tutorshipShape).isRequired,
};

export default InactiveTutorshipMessage;
