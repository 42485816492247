import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Header, Divider, Message } from 'semantic-ui-react';
import { withAsync } from '../common/withAsync';
import { fetchStudentApi } from './api';
import Tutorships from './tutorships';
import { studentType } from './types';
import StudentHeader from './studentHeader';

function StudentPage({
  data, invoke, match: { params: { studentId } },
}) {
  const [student, setStudent] = useState({});
  const [notification, setNotification] = useState('');

  useEffect(
    () => {
      if (data) {
        setStudent(data);
      }
    },
    [data],
  );

  useEffect(
    () => {
      invoke({ studentId });
    },
    [invoke, studentId],
  );

  const updateTutorshipWithMessage = useCallback((message) => {
    setNotification(message);
    setTimeout(() => {
      setNotification('');
    }, 5000);
    invoke({ studentId });
  }, [invoke, studentId]);

  if (!student) {
    return <React.Fragment />;
  }

  return (
    <React.Fragment>
      <StudentHeader student={student} key={student.id} />
      <br />
      <Divider clearing />
      <Header as="h4">Tutorships</Header>
      {notification && (
        <Message positive>
          <p>
            {notification}
          </p>
        </Message>
      )}
      <Tutorships
        tutorships={student.tutorships}
        student={student}
        updateTutorshipWithMessage={updateTutorshipWithMessage}
      />
    </React.Fragment>
  );
}

StudentPage.propTypes = {
  data: studentType.isRequired,
  invoke: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      studentId: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
};

export default withAsync(StudentPage, fetchStudentApi, false);
