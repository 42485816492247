import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Divider, Form, Header, Message,
} from 'semantic-ui-react';

import { withAsync } from '../common/withAsync';
import { Relationships } from './relationships';
import { UpdateStudentProfile } from './api';

function StudentProfileComponent({
  contact, invoke, data, onSuccess,
}) {
  const [values, setValues] = useState({});
  const [updated, setUpdated] = useState('');
  const [name, setName] = useState('');

  const handleChange = (event) => {
    event.persist();
    setValues(formValues => ({ ...formValues, [event.target.name]: event.target.value }));
  };

  useEffect(
    () => {
      setUpdated('');
      setValues({ ...contact.student, relationship_id: contact.relationship_id, id: contact.id });
    },
    [contact],
  );

  useEffect(
    () => {
      if (data && data.success) {
        setUpdated(`Success! You’ve updated the information for ${name}`);
      }
    },
    [data, name],
  );

  useEffect(
    () => {
      if (updated) onSuccess(contact.id);
    },
    [contact.id, onSuccess, updated],
  );

  return (
    <>
      {updated && <Message visible positive content={updated} />}
      <Form
        key={updated}
        onSubmit={() => {
          invoke({ id: values.id, data: { ...values } });
          setName(`${values.first_name} ${values.last_name}`);
          setUpdated('');
        }}
        noValidate
        autocomplete="on"
      >
        <Header as="h2">
          {values.first_name}
          {' '}
          {values.last_name}
        </Header>
        <Form.Group widths="equal">
          <Form.Input
            fluid
            label="First Name"
            autoComplete="given-name"
            name="first_name"
            value={values.first_name}
            onChange={handleChange}
          />
          <Form.Input
            fluid
            label="Last Name"
            name="last_name"
            autoComplete="family-name"
            value={values.last_name}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Input
            fluid
            label="Email"
            type="email"
            autoComplete="email"
            placeholder="john@example.com"
            value={values.email}
            name="email"
            onChange={handleChange}
          />
          <Form.Input
            fluid
            label="Phone #"
            type="tel"
            autoComplete="tel"
            placeholder="123-456-7890"
            name="phone"
            value={values.phone}
            onChange={handleChange}
          />
        </Form.Group>

        <Divider />
        <Relationships
          studentId={values.id}
          selectedId={values.relationship_id}
          onChange={(e, { value }) => {
            setValues(currentValues => ({ ...currentValues, relationship_id: value }));
          }}
        />
        <Divider hidden />
        <Button id="update-student-information" type="submit" primary>
          Confirm
        </Button>
      </Form>
    </>
  );
}

export const StudentShape = PropTypes.shape({
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
});

export const ContactShape = {
  student: StudentShape.isRequired,
  relationship_id: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
};

StudentProfileComponent.propTypes = {
  contact: ContactShape.isRequired,
  invoke: PropTypes.func.isRequired,
  data: PropTypes.shape({ sucess: PropTypes.bool }).isRequired,
  onSuccess: PropTypes.func,
};

StudentProfileComponent.defaultProps = {
  onSuccess: (contactId) => {},
};

export const StudentProfile = withAsync(StudentProfileComponent, UpdateStudentProfile, false);
