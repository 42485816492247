import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';

import { ListAllPayments } from './api';
import { PaymentTable } from './table';

import { withAsync } from '../common/withAsync';

export function PaymentComponent({ data, invoke }) {
  const [currentStudents, setCurrentStudents] = useState([]);
  const [currentCreditCards, setCurrentCreditCards] = useState([]);

  useEffect(
    () => {
      const cards = data.credit_cards ? data.credit_cards : [];
      const students = data.students ? data.students : [];
      const defaultCardId = cards.length === 0 ? 0 : cards.filter(creditCard => creditCard.preferred)[0].id;

      const studentsWithPaymentRequest = students.map((student) => {
        const amount = student.outstanding_balance > 0 ? student.outstanding_balance : 0;
        const paymentRequest = {
          creditCardId: defaultCardId,
          amount: parseInt(amount, 10) || 0,
        };
        return { ...student, paymentRequest };
      });
      setCurrentCreditCards(cards);
      setCurrentStudents(studentsWithPaymentRequest);
    },
    [data],
  );

  return (
    <>
      <Form>
        <PaymentTable
          students={currentStudents}
          creditCards={currentCreditCards}
          makePaymentRequests={() => {}}
          parentInvoke={invoke}
        />
      </Form>
    </>
  );
}

PaymentComponent.defaultProps = {
  data: {},
};

PaymentComponent.propTypes = {
  data: PropTypes.shape({
    students: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        outstanding_balance: PropTypes.number,
      }),
    ),
    credit_cards: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        card_number: PropTypes.string,
        preferred: PropTypes.bool,
      }),
    ),
  }),
};

export const Payment = withAsync(PaymentComponent, ListAllPayments, true);
