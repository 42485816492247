import React from 'react';
import { Switch, Route, withRouter } from 'react-router';
import { Grid } from 'semantic-ui-react';
import { Dashboard } from './components/dashboard';
import { Onboarding } from './components/onboarding';

import TutorshipContainer from './components/tutorship/tutorshipContainer';
import LandingPage from './components/growthTrackers/landingPage';
import FlashMessages from './components/flashMessages';
import Accounts from './components/accounts';
import './app.scss';

const AppStyle = {
  fontFamily: 'Nunito, sans-serif',
};

export const PasswordLandingPage = withRouter((props) => {
  const urlParams = new URLSearchParams(props.location.search);
  const isRegistration = urlParams.get('registration');
  if (isRegistration !== null) {
    return <Onboarding {...props} />;
  }
  return <Accounts {...props} />;
});

export function App() {
  return (
    <Grid style={AppStyle} padded>
      <Grid.Row>
        <Grid.Column>
          <FlashMessages />
          <Switch>
            <Route path="/customers/:customerId/tutorships/:tutorshipId" component={TutorshipContainer} />
            <Route path="/accounts/password/edit" component={PasswordLandingPage} />
            <Route path="/accounts" component={Accounts} />
            <Route path="/onboarding" component={Onboarding} />
            <Route path="/growth_trackers" component={LandingPage} />
            <Route component={Dashboard} />
          </Switch>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
