import React from 'react';
import { Grid, Message } from 'semantic-ui-react';

import { ContactUs } from '../messages';
import { HorizontallyAlignedLogo } from '../logo';

export const Header = () => (
  <Grid.Row>
    <Grid.Column
      className="mobile hidden"
      tablet={5}
      computer={5}
      largeScreen={3}
      widescreen={3}
      align="right"
    >
      <HorizontallyAlignedLogo />
    </Grid.Column>
    <Grid.Column only="mobile" mobile={16} align="center">
      <HorizontallyAlignedLogo />
    </Grid.Column>
    <Grid.Column mobile={16} tablet={11} computer={7} largeScreen={9} widescreen={9}>
      <Message info visible align="center">
        <ContactUs />
      </Message>
    </Grid.Column>
  </Grid.Row>
);
