import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';

import { tutorshipShape } from './shapes';
import Header from './header';
import GrowthTrackerContainer from './growthTrackerContainer';
import Referrals from './referrals';
import { withAsync } from '../common/withAsync';
import { fetchTutorship } from './api';
import TutorshipUpdateContext from './tutorshipUpdateContext';
import InactiveTutorshipMessage from './inactiveTutorshipMessage';

const TutorshipLandingPage = (props) => {
  const {
    data, invoke, tutorshipId, customerId,
  } = props;
  const [tutorship, setTutorship] = useState({});
  const [notification, setNotification] = useState('');

  useEffect(
    () => {
      setTutorship(data);
    },
    [data],
  );

  useEffect(
    () => {
      invoke({ tutorshipId });
    },
    [invoke, tutorshipId],
  );

  const updateTutorshipWithMessage = useCallback((message) => {
    setNotification(message);
    setTimeout(() => {
      setNotification('');
    }, 5000);
    invoke({ tutorshipId });
  }, [invoke, tutorshipId]);


  return (
    <TutorshipUpdateContext.Provider
      value={{
        updateTutorshipWithMessage,
        tutorshipId,
        customerId,
      }}
    >
      <Grid padded>
        <Grid.Row>
          <Grid.Column>
            <InactiveTutorshipMessage tutorship={tutorship} />
            <Header tutorship={tutorship} customerId={customerId} tutorshipId={tutorshipId} />
            <Referrals referrals={tutorship.referrals} />
            <GrowthTrackerContainer
              tutorship={tutorship}
              tutorshipId={tutorshipId}
              notification={notification}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </TutorshipUpdateContext.Provider>
  );
};

TutorshipLandingPage.defaultProps = {
  data: {},
};

TutorshipLandingPage.propTypes = {
  data: PropTypes.shape(tutorshipShape),
  invoke: PropTypes.func.isRequired,
  tutorshipId: PropTypes.string.isRequired,
  customerId: PropTypes.string.isRequired,
};

export default withAsync(TutorshipLandingPage, fetchTutorship, false);
