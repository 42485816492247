import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';

import { withAsync } from '../common/withAsync';
import { formatAmount } from '../helper';
import { FetchOutstandingBalance } from './api';

export function PendingBillBannerComponent({ data }) {
  const [outstandingBalance, setOutstandingBalance] = useState(undefined);

  useEffect(
    () => {
      setOutstandingBalance(data.balance);
    },
    [data.balance],
  );

  if (outstandingBalance === undefined || outstandingBalance === 0.0) {
    return <></>;
  }

  return (
    outstandingBalance && (
      <>
        <Button id="pay-balance" as={Link} to="/dashboard/payment" color="red" aria-label="Make Payment" fluid>
          Pay Balance:
          {' '}
          {formatAmount(outstandingBalance)}
        </Button>
      </>
    )
  );
}

PendingBillBannerComponent.propTypes = {
  data: PropTypes.shape({
    outstandingBalance: PropTypes.number,
  }),
};

PendingBillBannerComponent.defaultProps = {
  data: {},
};

export const PendingBillBanner = withAsync(
  PendingBillBannerComponent,
  FetchOutstandingBalance,
  true,
);
