import React from 'react';
import { Icon, Menu } from 'semantic-ui-react';

import { PAYMENT_OPTIONS } from '../constants';

export const BillingMenu = ({ setOption, selectedOption }) => {
  const onButtonClick = (e) => {
    e.target.scrollIntoView();
  };
  return (
    <Menu widths={PAYMENT_OPTIONS.length} secondary pointing stackable>
      {PAYMENT_OPTIONS.map(option => (
        <Menu.Item
          name={option.value}
          key={option.text}
          active={option.value === selectedOption}
          onClick={(e) => {
            setOption(option.value);
            onButtonClick(e);
          }}
        >
          <Icon color="green" size="large" name={option.icon} />
          {option.text}
        </Menu.Item>
      ))}
    </Menu>
  );
};

export const SelectedBillingMenuItem = (value) => {
  const selectedOption = PAYMENT_OPTIONS.find(option => option.value === value);
  if (selectedOption) {
    return selectedOption.text;
  }
};
