export const ZINC_LOGO_VERTICAL_URL = '/zinc-educational-services-vertical.png';
export const ZINC_LOGO_HORIZONTAL_URL = '/zinc-educational-services-horizontal.png';

export const ZINC_EMAIL_ADDRESS = 'info@zinc.nyc';

// value should match enum declared in payment_configuration under credit_card_debit_type
export const DEBIT_TYPES = [
  {
    key: 'auto',
    value: 'auto_debit',
    text: 'Go ahead and bill my card on the 1st and 15th',
  },
  {
    key: 'manual',
    value: 'manual_debit',
    text: 'I will log in and confirm each payment',
  },
];

// value should match enum declared in payment_configuration
export const PAYMENT_TYPE_CC = 'credit_card';
export const PAYMENT_TYPE_WIRE_TRANSFER = 'wire_transfer';
export const PAYMENT_TYPE_CHECK = 'check';

export const PAYMENT_OPTIONS = [
  {
    key: PAYMENT_TYPE_CC,
    value: PAYMENT_TYPE_CC,
    icon: 'cc',
    text: 'Credit Card',
  },
  {
    key: PAYMENT_TYPE_WIRE_TRANSFER,
    value: PAYMENT_TYPE_WIRE_TRANSFER,
    icon: 'lightning',
    text: 'Wire Transfer',
  },
  {
    key: PAYMENT_TYPE_CHECK,
    value: PAYMENT_TYPE_CHECK,
    icon: 'wordpress forms',
    text: 'Check',
  },
];
