import React, { useEffect, useState } from 'react';
import { Message } from 'semantic-ui-react';

import { withAsync } from '../common/withAsync';
import { PaymentSchedule } from '../messages';
import { FetchPaymentMethodApi } from './api';
import { PaymentOptions } from './paymentOptions';

function BillingPageComponent({ data, onSuccess, invoke }) {
  const [paymentInfo, setPaymentInfo] = useState({});

  useEffect(
    () => {
      setPaymentInfo(data);
    },
    [data],
  );

  return (
    <>
      <Message visible info>
        <PaymentSchedule />
      </Message>
      <PaymentOptions paymentInfo={paymentInfo} onSuccess={onSuccess} updatePaymentInfo={invoke} />
    </>
  );
}

BillingPageComponent.defaultProps = {
  data: {},
};

export const BillingPage = withAsync(BillingPageComponent, FetchPaymentMethodApi, true);
