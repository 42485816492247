import PropTypes from 'prop-types';
import { MOCK_TEST_TYPE, OFFICIAL_TEST_TYPE } from './constants';

export const tutorshipShape = {
  customer: PropTypes.shape({
    name: PropTypes.string,
  }),
  tutor: PropTypes.shape({
    name: PropTypes.string,
    rate: PropTypes.number,
    commission: PropTypes.string,
  }),
  subject_names: PropTypes.string,
  growth_tracker_available: PropTypes.bool,
  customer_type: PropTypes.oneOf(['Student', 'Division']),
};

export const GrowthTrackerShape = {
  id: PropTypes.number,
  test_type: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  createdAt: PropTypes.string,
};

export const TestShape = {
  id: PropTypes.number,
  name: PropTypes.string,
  subscores: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })),
};

export const ScoreTypeShape = PropTypes.oneOf([MOCK_TEST_TYPE, OFFICIAL_TEST_TYPE]);

export const SubscoreShape = {
  id: PropTypes.number,
  score: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  growth_tracker_test_subscore: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
};

export const scoreSet = {
  id: PropTypes.number,
  subscores: PropTypes.arrayOf(PropTypes.shape(SubscoreShape)),
};

export const ScoreShape = {
  id: PropTypes.number,
  score_type: ScoreTypeShape,
  raw_score_set: PropTypes.shape(scoreSet),
  scaled_score_set: PropTypes.shape(scoreSet),
  name: PropTypes.string,
};

export const ReferralShape = {
  name: PropTypes.string,
  rate: PropTypes.string,
  expiration_date: PropTypes.string,
};

export const ZincScoreShape = {
  id: PropTypes.number,
  score_type: PropTypes.oneOf(['Scaled', 'Raw']),
  scores: PropTypes.arrayOf(PropTypes.shape(SubscoreShape)),
  name: PropTypes.string,
};
