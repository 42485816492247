import React from 'react';
import { Button, Message, Form } from 'semantic-ui-react';
import getTag from '../../util/metatag';

export default function PasswordResetPage() {
  const csrfToken = getTag('csrf-token');
  return (
    <Form action="/accounts/password" method="post" acceptCharset="UTF-8">
      <input type="hidden" name="authenticity_token" value={csrfToken} />
      <Message info visible>Forgot your password? We’ll email you a link to reset your password.</Message>
      <Form.Input required label="Email" id="email" name="account[email]" autoFocus />
      <Button name="commit" primary id="reset-password">
        Reset Password
      </Button>
    </Form>
  );
}
