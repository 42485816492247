import { instance } from '../../config/api';

export const fetchStudentsApi = async ({ page, onlyPending }) => {
  try {
    const result = await instance.get(`/students.json?page=${page}&only_pending=${onlyPending}`, {});
    if (result.status === 200) {
      return result.data;
    }
  } catch (error) {
    return { error };
  }
};

export const fetchStudentApi = async ({ studentId }) => {
  try {
    const result = await instance.get(`/students/${studentId}.json`, {});
    if (result.status === 200) {
      return result.data;
    }
  } catch (error) {
    return { error };
  }
};

export const markAsConvertedApi = async ({ studentId }) => {
  try {
    const result = await instance.patch(`/students/${studentId}/mark_as_converted.json`);
    if (result.status === 204) {
      return { success: true };
    }
  } catch (error) {
    return { error };
  }
};
