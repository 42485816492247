import { instance } from '../../config/api';

const personProfileUrl = '/people.json';
const studentsProfileUrl = '/contacts.json';
const studentProfileUrl = id => `/contacts/${id}.json`;
const relationshipsUrl = '/relationships.json';

export const FetchPersonProfile = async () => {
  try {
    const result = await instance.get(personProfileUrl, {});
    if (result.status === 200) {
      return result.data;
    }
    return { error: 'unknown error' };
  } catch (error) {
    return { error: error.message };
  }
};

export const UpdatePersonProfile = async ({ data }) => {
  try {
    const result = await instance.put(personProfileUrl, { person: data });
    if (result.status === 204) {
      return { success: true };
    }
    if (result.status === 200) {
      return result.data;
    }
    return { error: 'unknown error' };
  } catch (error) {
    return { error: error.message };
  }
};

export const FetchStudentsProfiles = async () => {
  try {
    const result = await instance.get(studentsProfileUrl, {});
    if (result.status === 200) {
      return result.data;
    }
    return { error: 'unknown error' };
  } catch (error) {
    return { error: error.message };
  }
};

export const UpdateStudentProfile = async ({ id, data }) => {
  try {
    const result = await instance.put(studentProfileUrl(id), { contact: data });
    if (result.status === 204) {
      return { success: true };
    }
    if (result.status === 200) {
      return result.data;
    }
    return { error: 'unknown error' };
  } catch (error) {
    return { error: error.message };
  }
};

export const FetchRelationships = async () => {
  try {
    const result = await instance.get(relationshipsUrl, {});
    if (result.status === 200) {
      return result.data;
    }
    return { error: 'unknown error' };
  } catch (error) {
    return { error: error.message };
  }
};
