import React from 'react';
import { Header, Table } from 'semantic-ui-react';

import { formatAmount, ExternalLink } from '../helper';

export function TutorshipsTable({ tutorships, nonePresentMessage }) {
  if (tutorships.length === 0) {
    return <Header as="h3">{nonePresentMessage}</Header>;
  }
  return (
    <div>
      <Table celled unstackable color="green">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Tutor</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>Subjects</Table.HeaderCell>
            <Table.HeaderCell textAlign="right">Hourly Rate</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {tutorships.map(tutorship => (
            <Table.Row key={tutorship.id}>
              <Table.Cell>{tutorship.tutor.name}</Table.Cell>
              <Table.Cell>
                <ExternalLink
                  type="mail-tutor"
                  link={`mailto:${tutorship.tutor.email}`}
                  text={tutorship.tutor.email}
                  ariaLabel={`${tutorship.tutor.name}’s email'`}
                />
              </Table.Cell>
              <Table.Cell>{tutorship.subjects.join(', ')}</Table.Cell>
              <Table.Cell textAlign="right">{formatAmount(tutorship.hourly_rate)}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
}
