import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Table } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { withAsync } from '../common/withAsync';
import { fetchStudentsApi } from './api';
import { PaginatedContent } from '../common/paginatedContent';
import { studentType } from './types';

function Students({
  data, invoke, onlyPending,
}) {
  const [studentsInfo, setStudentsInfo] = useState({});

  const fetchStudents = useCallback(({ page }) => {
    invoke({ onlyPending, page });
  }, [invoke, onlyPending]);

  useEffect(
    () => {
      if (data) {
        setStudentsInfo(data);
      }
    },
    [data],
  );

  useEffect(
    () => {
      fetchStudents({ page: 1 });
    },
    [fetchStudents],
  );

  const { students, meta } = studentsInfo;
  const totalPages = meta ? meta.total_pages : 0;
  return (
    <PaginatedContent
      key={`students-with-pending-${onlyPending}`}
      loading={false}
      fetchItems={fetchStudents}
      items={students}
      totalPages={totalPages}
      noRowsContent="There are no students for this filter"
    >
      <Table unstackable striped compact>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>ID</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Growth Tracker Status</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {students && students.map(student => (
            <Table.Row>
              <Table.Cell>{student.id}</Table.Cell>
              <Table.Cell>{student.name}</Table.Cell>
              <Table.Cell>{student.growth_tracker ? 'Present' : 'NA'}</Table.Cell>
              <Table.Cell>
                <Button id="edit" primary as={Link} to={`/growth_trackers/${student.id}`}>Edit</Button>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </PaginatedContent>
  );
}

Students.propTypes = {
  data: studentType.isRequired,
  invoke: PropTypes.func.isRequired,
  onlyPending: PropTypes.bool.isRequired,
};

export default withAsync(Students, fetchStudentsApi, false);
