import React from 'react';
import PropTypes from 'prop-types';
import { Table, Dropdown, Label } from 'semantic-ui-react';

import { ZincScoreShape, TestShape } from './shapes';
import SubscoreCells from './subscoreCells';
import MarkAsBaselineScore from './markAsBaselineScore';
import { ZINC_SCORE_BASELINE_SCORE_TYPE } from './constants';

const ZincScoreRow = ({
  test, score, growthTrackerId, baselineScoreSection,
}) => {
  const highlightBaselineScore = !baselineScoreSection && score.baseline;
  return (
    <React.Fragment>
      <Table.Row key={`raw-score-body-${score.id}`}>
        <Table.Cell>
          {highlightBaselineScore
            && (
            <React.Fragment>
              <Label ribbon>Baseline Score</Label>
              <br />
            </React.Fragment>
            )}
          {score.name}
          <br />
          <Label size="mini">Zinc Mock</Label>
        </Table.Cell>
        <Table.Cell>
          {score.test_date}
        </Table.Cell>
        <Table.Cell>{score.score_type}</Table.Cell>
        <SubscoreCells test={test} subscores={score.scores} />
        {!baselineScoreSection && (
        <Table.Cell width="2">
          {!score.baseline && (
          <Dropdown text="Actions" closeOnChange closeOnEscape>
            <Dropdown.Menu>
              <MarkAsBaselineScore
                scoreId={score.id}
                scoreType={ZINC_SCORE_BASELINE_SCORE_TYPE}
                growthTrackerId={growthTrackerId}
              />
            </Dropdown.Menu>
          </Dropdown>
          )}
        </Table.Cell>
        )}
      </Table.Row>
    </React.Fragment>
  );
};


ZincScoreRow.propTypes = {
  test: PropTypes.shape(TestShape).isRequired,
  score: PropTypes.shape(ZincScoreShape).isRequired,
  growthTrackerId: PropTypes.number.isRequired,
  baselineScoreSection: PropTypes.bool.isRequired,
};

export default ZincScoreRow;
