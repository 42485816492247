import React from 'react';

export const formatAmount = amount => amount.toLocaleString('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const ExternalLink = ({ link, text, ariaLabel, type }) => (
  <a href={link} id={`external-link-${type}`} target="_blank" rel="noopener noreferrer" aria-label={ariaLabel}>
    {text}
  </a>
);
