import React, { useState, useEffect } from 'react';

import { Button, Form } from 'semantic-ui-react';
import { CreditCardIcon } from './index';

export const CreditCardForm = ({
  name, cardNumber, expirationDate, address, city, state, country, zip, update, close,
}) => {
  const [currentName, setCurrentName] = useState('');
  const [currentCardNumber, setCurrentCardNumber] = useState('');
  const [currentExpirationDate, setCurrentExpirationDate] = useState('');
  const [currentCvvNumber, setCurrentCvvNumber] = useState('');
  const [currentAddress, setCurrentAddress] = useState('');
  const [currentCity, setCurrentCity] = useState('');
  const [currentState, setCurrentState] = useState('');
  const [currentCountry, setCurrentCountry] = useState('');
  const [currentZip, setCurrentZip] = useState('');

  useEffect(
    () => {
      setCurrentName(name);
      setCurrentCardNumber(cardNumber);
      setCurrentExpirationDate(expirationDate);
      setCurrentAddress(address);
      setCurrentCity(city);
      setCurrentState(state);
      setCurrentCountry(country);
      setCurrentZip(zip);
    },

    [name, cardNumber, expirationDate, address, city, state, country, zip],
  );

  const updateDetails = () => {
    const firstName = currentName.split(' ')[0];
    const lastName = currentName
      .split(' ')
      .splice(1)
      .join(' ');

    const expirationMonth = currentExpirationDate.split('/')[0];
    const expirationYear = currentExpirationDate.split('/')[1];

    update({
      payment_profile: {
        first_name: firstName,
        last_name: lastName,
        card_number: currentCardNumber,
        expiration_month: expirationMonth,
        expiration_year: expirationYear,
        cvv_number: currentCvvNumber,
        address: currentAddress,
        city: currentCity,
        state: currentState,
        country: currentCountry,
        zip: currentZip,
      },
    });
  };

  const isInvalid = !(currentName && currentCardNumber && currentExpirationDate && currentCvvNumber && currentAddress && currentCity && currentState && currentCountry && currentZip);

  return (
    <Form autoComplete="on">
      <Form.Group widths="equal">
        <Form.Input
          fluid
          label="Name on Card"
          placeholder="NAME"
          autoComplete="cc-name"
          value={currentName}
          onChange={e => setCurrentName(e.target.value)}
          autoFocus
        />

        <Form.Input
          fluid
          label="Card Number"
          placeholder="Card Number"
          value={currentCardNumber}
          autoComplete="cc-number"
          onChange={e => setCurrentCardNumber(e.target.value)}
        />

        <Form.Input
          fluid
          type="text"
          label="Expiration"
          placeholder="MM/YYYY"
          value={currentExpirationDate}
          autoComplete="cc-exp"
          onChange={event => setCurrentExpirationDate(event.target.value)}
        />
      </Form.Group>

      <Form.Group widths="equal">
        <Form.Input
          fluid
          label="Cvv code"
          placeholder="CVV CODE"
          value={currentCvvNumber}
          onChange={e => setCurrentCvvNumber(e.target.value)}
          autoFocus
        />
      </Form.Group>

      <Form.Group widths="equal">
        <Form.Input
          fluid
          label="Address"
          placeholder="Address"
          value={currentAddress}
          onChange={e => setCurrentAddress(e.target.value)}
        />
      </Form.Group>

      <Form.Group widths="equal">

        <Form.Input
          fluid
          label="City"
          placeholder="City"
          value={currentCity}
          onChange={e => setCurrentCity(e.target.value)}
        />

        <Form.Input
          fluid
          label="State"
          placeholder="State"
          value={currentState}
          onChange={e => setCurrentState(e.target.value)}
        />

        <Form.Input
          fluid
          label="Country"
          placeholder="Country"
          value={currentCountry}
          onChange={e => setCurrentCountry(e.target.value)}
        />

        <Form.Input
          fluid
          label="Zip"
          placeholder="Zip"
          value={currentZip}
          onChange={e => setCurrentZip(e.target.value)}
        />

      </Form.Group>

      <Button primary type="submit" onClick={updateDetails} disabled={isInvalid} id="update-credit-card">
        <CreditCardIcon />
        Save
      </Button>
      <Button basic onClick={close}>
        Close
      </Button>
    </Form>
  );
};
