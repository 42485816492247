import React from 'react';
import {
  Card, Grid, Header, Label, Segment,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { studentType } from './types';
import MarkAsConvertedAction from './markAsConvertedAction';

function StudentHeader({ student }) {
  return (
    <React.Fragment>
      <Grid>
        <Grid.Column width={4}>
          <Card>
            <Card.Content>
              <Card.Header>Student</Card.Header>
              <Card.Description>
                <a href={`/customers/${student.id}`}>
                  {student.name}
                </a>
              </Card.Description>
              <br />
              <Label as="a" color="red" ribbon>
                {student.conversion_status === 'pending_conversion' ? 'Pending Conversion' : 'Converted'}
              </Label>
            </Card.Content>
          </Card>
        </Grid.Column>
        <Grid.Column width={4}>
          <Card>
            <Card.Content>
              <Card.Header>Links</Card.Header>
              <Card.Description>
                <Link to={`/growth_trackers/${student.next_student_id}`}>Next Student</Link>
                <br />
                <Link to="/growth_trackers">Back to all students</Link>
                <br />
                <br />
              </Card.Description>
            </Card.Content>
          </Card>
        </Grid.Column>
        <Grid.Column width={4}>
          <Card>
            <Card.Content>
              <Card.Header>Actions</Card.Header>
              <Card.Description>
                <MarkAsConvertedAction student={student} />
              </Card.Description>
            </Card.Content>
          </Card>
        </Grid.Column>
      </Grid>
      <Grid>
        <Grid.Column width={8}>
          <Header as="h4" attached="top" block>
          Past Results
          </Header>
          <Segment attached>{student.past_results}</Segment>
        </Grid.Column>
        <Grid.Column width={8}>
          <Header as="h4" attached="top" block>
          Zinc Results
          </Header>
          <Segment attached>{student.zinc_results}</Segment>
        </Grid.Column>
      </Grid>
    </React.Fragment>
  );
}

StudentHeader.propTypes = {
  student: studentType.isRequired,
};

export default StudentHeader;
