import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Header, Menu, Tab } from 'semantic-ui-react';

/* NOTE: we cannot add icons to the menu name etc., we should use Menu with content.
        Also, this only supports horizontal tabs with content below it.
 */
/*
  sample syntax for tabs:
  [{
    name: "Name of Tab",
    element: <Element />
  }]
  */

const DefaultTabStyle = {
  color: 'green',
  attached: true,
  tabular: true,
};

export function RenderTabbedViews({
  menuItems,
  selectedTabIndex,
  widths,
  skipTabsIfOnlyOneItem,
  showHeadingIfOnlyOneItem,
}) {
  const [selectedTab, setSelectedTab] = useState(-1);
  useEffect(
    () => {
      setSelectedTab(selectedTabIndex);
    },
    [selectedTabIndex],
  );

  useEffect(
    () => {
      setSelectedTab(selectedTabIndex);
    },
    [selectedTabIndex],
  );

  if (skipTabsIfOnlyOneItem && menuItems.length === 1) {
    return (
      <>
        {showHeadingIfOnlyOneItem && <Header as="h3">{menuItems[0].name}</Header>}
        {menuItems[0].element}
      </>
    );
  }
  const panes = menuItems.map((item) => {
    const menuItem = item.isLink ? (
      <Menu.Item key={item.link} as={Link} to={item.link} aria-label={item.name}>
        <strong>{item.name}</strong>
      </Menu.Item>
    ) : (
      <Menu.Item aria-label={item.name}>
        <strong>{item.name}</strong>
      </Menu.Item>
    );
    return {
      menuItem,
      icon: 'book',
      render: () => <Tab.Pane key={item.link}>{item.element}</Tab.Pane>,
    };
  });

  if (selectedTab === -1) {
    return <></>;
  }

  return (
    <Tab
      onTabChange={(e, { activeIndex }) => setSelectedTab(activeIndex)}
      menu={{ ...DefaultTabStyle, widths }}
      defaultActiveIndex={selectedTab}
      panes={panes}
    />
  );
}
