import React, { useEffect, useCallback } from 'react';

import { withAsync } from '../common/withAsync';

import { UpdateCreditCardApi } from './api';
import { CreditCardForm } from './form';

export const EditFormComponent = ({
  invoke, close, cardId, name, cardNumber, cvvNumber, address, city, state, country, zip, data, changeSuccessful,
}) => {
  useEffect(() => {
    if (data && data.success) {
      changeSuccessful();
    }
  }, [changeSuccessful, data]);

  const update = useCallback((updateParams) => {
    invoke({ cardId, data: updateParams });
  }, [invoke, cardId]);

  return (
    <CreditCardForm
      update={update}
      close={close}
      name={name}
      cardNumber={cardNumber}
      cvvNumber={cvvNumber}
      address={address}
      city={city}
      state={state}
      country={country}
      zip={zip}
    />
  );
};

export const EditForm = withAsync(EditFormComponent, UpdateCreditCardApi, false);
