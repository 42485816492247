import { instance } from '../../config/api';

const tutorshipsForCustomerUrl = customerId => `/customers/${customerId}/tutorships.json`;

export const FetchTutorshipsForStudent = async ({ id }) => {
  try {
    const result = await instance.get(tutorshipsForCustomerUrl(id), {});
    if (result.status === 200) {
      return result.data;
    }
  } catch (error) {
    return { error: error.message };
  }
};
