import PropTypes from 'prop-types';
import { tutorshipShape } from '../tutorship/shapes';

export const studentType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  growth_tracker: PropTypes.bool.isRequired,
  past_results: PropTypes.string,
  zinc_results: PropTypes.string,
  conversion_status: PropTypes.oneOf(['pending_conversion', 'converted']),
  next_student_id: PropTypes.number,
  tutorships: PropTypes.arrayOf(PropTypes.shape(tutorshipShape)),
});
