import React from 'react';
import {
  Button, Container, Divider, Header, Segment,
} from 'semantic-ui-react';

export function SignOutPage(props) {
  return (
    <Segment padded="very" raised>
      <Container fluid text align="center">
        <Divider hidden />
        <Header as="h3">Are you sure you want to sign out?</Header>
        <Button id="sign-out" primary as="a" href="/accounts/logout">
          Sign Out
        </Button>
        <Button id="goto-dashboard" basic as="a" href="/" aria-label="Home Page">
          Take me to the Dashboard
        </Button>
      </Container>
    </Segment>
  );
}
