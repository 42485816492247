import React from 'react';
import { Grid, Header, Segment } from 'semantic-ui-react';
import TutorshipUpdateContext from '../tutorshipUpdateContext';

function PastScores() {
  return (
    <TutorshipUpdateContext.Consumer>
      {({ showPastScores, pastResults, zincResults }) => {
        if (!showPastScores) {
          return null;
        }

        return (
          <Grid columns={2}>
            <Grid.Row>
              <Grid.Column>
                <Header as="h4" attached="top" block>
                Past Results
                </Header>
                <Segment attached>{pastResults}</Segment>
              </Grid.Column>
              <Grid.Column>
                <Header as="h4" attached="top" block>
                Zinc Results
                </Header>
                <Segment attached>{zincResults}</Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        );
      }}
    </TutorshipUpdateContext.Consumer>
  );
}

export default PastScores;
