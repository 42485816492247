import React from 'react';
import { Button, Form, Header } from 'semantic-ui-react';
import getTag from '../../util/metatag';

export default function editPassword() {
  const csrfToken = getTag('csrf-token');
  return (
    <Form action="/accounts" method="post" acceptCharset="UTF-8">
      <input type="hidden" name="_method" value="patch" />
      <input type="hidden" name="authenticity_token" value={csrfToken} />
      <Header as="h2">Update Password</Header>
      <Form.Input
        required
        type="password"
        autoComplete="current-password"
        label="Current Password"
        name="account[current_password]"
        autoFocus
      />
      <Form.Input
        required
        type="password"
        autoComplete="new-password"
        label="New Password"
        name="account[password]"
      />
      <Form.Input
        required
        type="password"
        autoComplete="new-password"
        label="Confirm New Password"
        name="account[password_confirmation]"
      />
      <Button name="commit" primary id="edit-password">
        Update Password
      </Button>
      <Button basic as="a" href="/" aria-label="Home Page">
        Cancel
      </Button>
    </Form>
  );
}
