import React from 'react';

import { Divider } from 'semantic-ui-react';

import { PendingBillBanner } from './pendingBillBanner';
import { StudentsSummary } from './studentsSummary';

export function DashboardLanding() {
  return (
    <>
      <PendingBillBanner />
      <Divider hidden />
      <StudentsSummary />
    </>
  );
}
