import React, { useState, useEffect } from 'react';
import {
  Button, Divider, Grid, Header, Message, Segment,
} from 'semantic-ui-react';

import { PAYMENT_TYPE_WIRE_TRANSFER } from '../constants';

import {
  CheckPaymentInstructions,
  WireTransferPaymentInstructions,
  AskForBackupCreditCard,
} from '../messages';

import { PendingConfirmationMessage } from './pendingConfirmationMessage';
import { WontProvideCreditCard } from './wontProvideCreditCard';
import { CreditCardList } from '../creditCards';
import { ListAllCreditCards } from '../creditCards/api';
import { withAsync } from '../common/withAsync';

export function BackupCreditCardComponent({
  paymentInfo,
  updatePaymentMethod,
  next,
  paymentType,
  invoke,
  data,
}) {
  const [creditCards, setCreditCards] = useState([]);

  const [updateMessage, setUpdateMessage] = useState(false);

  const reloadCreditCards = ({ message }) => {
    setUpdateMessage('');
    if (message) {
      setUpdateMessage(message);
    }
    invoke();
  };

  useEffect(
    () => {
      setCreditCards(data);
    },
    [data],
  );

  const [wontProvideCreditCard, setWontProvideCreditCard] = useState(false);

  const isValid = creditCards.length !== 0 || !paymentInfo.requires_mandatory_credit_card;
  const nextElement = paymentType === PAYMENT_TYPE_WIRE_TRANSFER ? (
    <WireTransferPaymentInstructions />
  ) : (
    <CheckPaymentInstructions />
  );

  return (
    <>
      <AskForBackupCreditCard />
      <Segment>
        {updateMessage && <Message visible positive content={updateMessage} />}
        <CreditCardList
          hideNewCard={isValid}
          creditCards={creditCards}
          reload={reloadCreditCards}
        />
      </Segment>

      {!isValid && (
        <WontProvideCreditCard
          setChecked={setWontProvideCreditCard}
          checked={wontProvideCreditCard}
        />
      )}
      {(isValid || wontProvideCreditCard || paymentInfo.billing_type !== paymentType) && (
        <Header as="h4">{nextElement}</Header>
      )}
      <PendingConfirmationMessage paymentType={paymentType} paymentInfo={paymentInfo} />
      <Divider hidden />
      <Grid centered>
        <Button
          primary
          disabled={!isValid && !wontProvideCreditCard}
          onClick={() => {
            updatePaymentMethod();
          }}
          id="update-payment-method"
        >
          Confirm
        </Button>
      </Grid>
      <Divider hidden />
    </>
  );
}

BackupCreditCardComponent.defaultProps = {
  data: [],
};

export const BackupCreditCard = withAsync(BackupCreditCardComponent, ListAllCreditCards, true);
