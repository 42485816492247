export const MOCK_TEST_TYPE = 'mock_test';
export const OFFICIAL_TEST_TYPE = 'official_test';
export const STUDENT_CUSTOMER = 'Student';

export const ZINC_SCORE_TYPE = 'zinc_score';

export const GROWTH_TRACKER_BASELINE_SCORE_TYPE = 'GrowthTrackerScore';
export const ZINC_SCORE_BASELINE_SCORE_TYPE = 'StudentTestResponse';

export const INACTIVE_STATUS = 'inactive';
export const ACTIVE_STATUS = 'active';
export const ADMIN_USER = 'Administrator';
