import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  Form,
  Input,
} from 'semantic-ui-react';
import { ScoreShape } from '../shapes';

const ScoreName = ({
  updateScore, existingScore, editMode,
}) => {
  const [scoreName, setScoreName] = useState('');

  useEffect(() => {
    updateScore({ scoreName });
  }, [scoreName, updateScore]);


  useEffect(() => {
    if (!editMode) {
      return;
    }
    setScoreName(existingScore.name);
  }, [editMode, existingScore]);

  const handleScoreNameChange = (e, { value }) => {
    setScoreName(value);
  };

  return (
    <Form.Group key="score_name" unstackable>
      <Form.Field required>
        <label>Name</label>
        <Form.Group key="score_name" unstackable inline>
          <Form.Field
            control={Input}
            value={scoreName}
            onChange={handleScoreNameChange}
          />
        </Form.Group>
      </Form.Field>
    </Form.Group>
  );
};

ScoreName.defaultProps = {
  editMode: false,
  existingScore: {},
};

ScoreName.propTypes = {
  editMode: PropTypes.bool,
  existingScore: PropTypes.shape(ScoreShape),
  updateScore: PropTypes.func.isRequired,
};

export default ScoreName;
