import React from 'react';
import { Button, Message, Form } from 'semantic-ui-react';

import getTag from '../../util/metatag';

export default function SignInPage() {
  const csrfToken = getTag('csrf-token');
  return (
    <Form action="/accounts/login" method="post" acceptCharset="UTF-8" autoComplete="on">
      <input type="hidden" name="authenticity_token" value={csrfToken} />
      <Message visible info>
        Welcome to the Zinc Portal. Enter your email and password to access Zinc.
      </Message>
      <Form.Input
        required
        label="Email"
        id="email"
        name="account[email]"
        autoFocus
        autoComplete="email"
      />
      <Form.Input
        required
        label="Password"
        type="password"
        id="password"
        name="account[password]"
        autoComplete="current-password"
      />
      <Button primary name="commit" id="account[sign_in]" type="submit">
        Sign In
      </Button>
    </Form>
  );
}
