import React, { useState, useCallback } from 'react';

import { ShowCard } from './show';
import { EditCard } from './edit';

export const CardRow = ({
  card, setCardAsDefault, deleteCard, reload,
}) => {
  const [view, setView] = useState('show');

  const changeSuccessful = useCallback(() => {
    setView('show');
    reload({ message: 'Card was updated successfully' });
  }, [reload]);

  return view === 'show' ? (
    <ShowCard
      card={card}
      setCardAsDefault={setCardAsDefault}
      deleteCard={deleteCard}
      setEditView={() => setView('edit')}
    />
  ) : (
    <EditCard
      cardId={card.id}
      cancelEdit={() => setView('show')}
      changeSuccessful={changeSuccessful}
    />
  );
};
