import React, { useState } from 'react';
import {
  Button, Message, Form, Header,
} from 'semantic-ui-react';
import getTag from '../../util/metatag';

export default function UpdatePassword(props) {
  const { resetToken } = props;
  const csrfToken = getTag('csrf-token');

  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const validate = (password, passwordConfirmation) => {
    const messages = [];
    if (password.length === 0) {
      messages.push('Password cannot be empty');
    } else if (password.length < 8) {
      messages.push('Password must be at least 8 characters long');
    }
    if (passwordConfirmation.length === 0) {
      messages.push('Password Confirmation cannot be empty');
    } else if (passwordConfirmation.length < 8) {
      messages.push('Password Confirmation must be at least 8 characters long');
    }
    if (passwordConfirmation !== password && messages.length === 0) {
      messages.push("Password Confirmation doesn't match Password");
    }
    return messages;
  };

  const submitAfterValidation = (e) => {
    const messages = validate(password, passwordConfirmation);
    if (messages.length > 0) {
      e.preventDefault();
      setErrorMessage(messages.join(', '));
    }
  };

  return (
    <Form
      action="/accounts/password"
      method="post"
      acceptCharset="UTF-8"
      autoComplete="on"
      error={errorMessage !== ''}
    >
      <>
        <Header as="h2">Create a Password</Header>
        {errorMessage && <Message visible error>{errorMessage}</Message>}
        <Form.Group widths="equal">
          <input type="hidden" value={resetToken} name="account[reset_password_token]" />
          <input type="hidden" name="_method" value="patch" />
          <input type="hidden" name="authenticity_token" value={csrfToken} />
          <Form.Input
            required
            type="password"
            id="password"
            autoComplete="new-password"
            label="New password"
            name="account[password]"
            onChange={e => setPassword(e.target.value)}
            placeholder="************"
            autoFocus
          />
          <Form.Input
            required
            type="password"
            id="confirm_password"
            label="Confirm new password"
            autoComplete="new-password"
            name="account[password_confirmation]"
            onChange={e => setPasswordConfirmation(e.target.value)}
            placeholder="************"
          />
        </Form.Group>

        <Button
          type="submit"
          name="commit"
          primary
          onClick={submitAfterValidation}
          id="create-password"
        >
          Create a Password
        </Button>
      </>
    </Form>
  );
}
