import React from 'react';
import { Checkbox, Message } from 'semantic-ui-react';

import { NoBackupCreditCardProvided } from '../messages';

export function WontProvideCreditCard({ checked, setChecked }) {
  return (
    <>
      <Checkbox
        toggle
        checked={checked}
        onChange={() => setChecked(!checked)}
        label="I do not wish to provide a Credit Card"
      />

      {checked && (
        <Message visible error>
          <NoBackupCreditCardProvided />
        </Message>
      )}
    </>
  );
}
