import React, { useState, useEffect } from 'react';

import {
  Divider, Header, Loader, Message,
} from 'semantic-ui-react';

import { AllTutorships } from '../tutorships/all';
import { ListAllPayments } from '../payment/api';

export function TutorshipsForAllStudents(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [students, setStudents] = useState([]);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);

      const response = await ListAllPayments();

      if (response && response.error) {
        setError(response.error);
      } else {
        setStudents(response.students);
        setError('');
      }
      setIsLoading(false);
    }
    fetchData();
  }, []);
  if (isLoading) {
    return <Loader active />;
  }
  if (error) {
    return <Message visible error>{error}</Message>;
  }

  return (
    <>
      {students.map(student => (
        <span key={student.id}>
          <Header as="h2">{student.name}</Header>
          <AllTutorships studentId={student.id} />
          <Divider hidden />
          <Divider hidden />
        </span>
      ))}
    </>
  );
}
