import { instance } from '../../config/api';

const creditCardsUrl = '/payment_profiles.json';
const creditCardUrl = cardId => `/payment_profiles/${cardId}.json`;
const editCreditCardUrl = cardId => `/payment_profiles/${cardId}/edit.json`;
const setAsDefaultCreditCardUrl = cardId => `/payment_profiles/${cardId}/set_as_default.json`;

export const AddCreditCardApi = async (data) => {
  try {
    const result = await instance.post(creditCardsUrl, data);
    if (result.status === 204) {
      return { success: true };
    }
    if (result.status === 200) {
      return result.data;
    }
  } catch (error) {
    return { error };
  }
};

export const SetDefaultCreditCardApi = async ({ cardId }) => {
  try {
    const result = await instance.put(setAsDefaultCreditCardUrl(cardId));
    if (result.status === 204) {
      return { success: true };
    }
    if (result.status === 200) {
      return result.data;
    }
  } catch (error) {
    return { error };
  }
};

export const DeleteCreditCardApi = async ({ cardId }) => {
  try {
    const result = await instance.delete(creditCardUrl(cardId), {});
    if (result.status === 204) {
      return { success: true };
    }
  } catch (error) {
    return { error };
  }
};

export const ListAllCreditCards = async () => {
  try {
    const result = await instance.get(creditCardsUrl, {});
    if (result.status === 200) {
      return result.data;
    }
  } catch (error) {
    return { error };
  }
};

export const EditCreditCardApi = async ({ cardId }) => {
  try {
    const result = await instance.get(editCreditCardUrl(cardId), {});
    if (result.status === 200) {
      return result.data;
    }
  } catch (error) {
    return { error };
  }
};

export const UpdateCreditCardApi = async ({ cardId, data }) => {
  try {
    const result = await instance.put(creditCardUrl(cardId), data);
    if (result.status === 204) {
      return { success: true };
    }
    if (result.status === 200) {
      return result.data;
    }
  } catch (error) {
    return { error };
  }
};
