import React, { useEffect } from 'react';
import { Card } from 'semantic-ui-react';
import moment from 'moment';
import PropTypes from 'prop-types';

import ButtonWithConfirmation from './buttonWithConfirmation';
import { withAsync } from '../common/withAsync';
import { withTutorshipUpdateContext } from './tutorshipUpdateContext';
import { deleteGrowthTracker } from './api';
import { GrowthTrackerShape } from './shapes';
import ExportScores from './exportScores';
import AddScoreModal from './addScoreModal';
import Scores from './scores';

const ViewGrowthTracker = ({
  growthTracker, data, invoke, updateTutorshipWithMessage, tutorshipId, customerId,
}) => {
  useEffect(
    () => {
      if (data && data.success) {
        updateTutorshipWithMessage('Growth tracker deleted successfully');
      }
    },
    [data, updateTutorshipWithMessage],
  );

  const deleteTracker = () => {
    invoke({ growthTrackerId: growthTracker.id });
  };

  if (!growthTracker || Object.keys(growthTracker).length === 0) {
    return <React.Fragment />;
  }

  const baselineScores = growthTracker.baseline_score ? [growthTracker.baseline_score] : [];
  const scores = [...growthTracker.scores, ...growthTracker.zinc_scores];

  const createdAt = moment(growthTracker.created_at).format('ll');
  return (
    <React.Fragment>
      <Card.Group>
        <Card fluid>
          <Card.Content>
            <Card.Header>
              {`Test: ${growthTracker.test.name}`}
            </Card.Header>
            <Card.Meta>
              <span className="date">
                {`Created on ${createdAt}`}
              </span>
            </Card.Meta>
          </Card.Content>
          <Card.Content extra>
            <AddScoreModal testId={growthTracker.test.id} growthTrackerId={growthTracker.id} />
            <ButtonWithConfirmation onClick={deleteTracker} text="Delete" />
            <ExportScores tutorshipId={tutorshipId} customerId={customerId} />
          </Card.Content>
        </Card>
      </Card.Group>
      <Scores
        testId={growthTracker.test.id}
        scores={baselineScores}
        growthTrackerId={growthTracker.id}
        title="Baseline Score"
        baselineScoreSection
      />
      <Scores
        testId={growthTracker.test.id}
        scores={scores}
        growthTrackerId={growthTracker.id}
        title="All Scores"
      />
    </React.Fragment>
  );
};

ViewGrowthTracker.defaultProps = {
  data: {},
  growthTracker: {},
};

ViewGrowthTracker.propTypes = {
  data: PropTypes.shape({
    success: PropTypes.string,
    error: PropTypes.string,
  }),
  growthTracker: PropTypes.shape(GrowthTrackerShape),
  invoke: PropTypes.func.isRequired,
  updateTutorshipWithMessage: PropTypes.func.isRequired,
  tutorshipId: PropTypes.string.isRequired,
  customerId: PropTypes.string.isRequired,
};

export default withTutorshipUpdateContext(withAsync(ViewGrowthTracker, deleteGrowthTracker, false));
