import React from 'react';
import PropTypes from 'prop-types';

import { Image } from 'semantic-ui-react';

import ZINC_LOGO_HORIZONTAL from '../assets/images/zinc-educational-services-horizontal.png';
import ZINC_LOGO_VERTICAL from '../assets/images/zinc-educational-services-vertical.png';

export const HorizontallyAlignedLogo = ({ centered }) => (
  <a href="/" aria-label="Dashboard">
    <Image
      alt="Zinc Logo"
      src={ZINC_LOGO_HORIZONTAL}
      style={{ maxHeight: '80px', padding: '0px 10px' }}
      size="medium"
      centered={centered}
    />
  </a>
);

HorizontallyAlignedLogo.defaultProps = {
  centered: true,
};

HorizontallyAlignedLogo.propTypes = {
  centered: PropTypes.bool,
};

export const VerticallyAlignedLogo = () => (
  <a href="/" aria-label="Dashboard">
    <Image alt="Zinc Logo" src={ZINC_LOGO_VERTICAL} style={{ width: '150px', padding: '10px' }} />
  </a>
);

const Examples = () => (
  <>
    <VerticallyAlignedLogo />
    <br />
    <HorizontallyAlignedLogo />
  </>
);

export default Examples;
