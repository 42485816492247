import { instance } from '../../config/api';

export const fetchTutorship = async ({ tutorshipId }) => {
  try {
    const result = await instance.get(`/tutorships/${tutorshipId}.json`, {});
    if (result.status === 200) {
      return result.data;
    }
  } catch (error) {
    return { error };
  }
};

export const fetchGrowthTrackerTests = async () => {
  try {
    const result = await instance.get('/growth_tracker_tests.json', {});
    if (result.status === 200) {
      return result.data;
    }
  } catch (error) {
    return { error };
  }
};

export const createGrowthTracker = async ({ testId, tutorshipId }) => {
  try {
    const result = await instance.post('/growth_trackers.json', { growth_tracker: { growth_tracker_test_id: testId, tutorship_id: tutorshipId } });
    if (result.status === 200) {
      return result.data;
    }
  } catch (error) {
    return { error };
  }
};

export const deleteGrowthTracker = async ({ growthTrackerId }) => {
  try {
    const result = await instance.delete(`/growth_trackers/${growthTrackerId}/delete.json`, {});
    if (result.status === 200) {
      return result.data;
    }
  } catch (error) {
    return { error };
  }
};

export const createGrowthTrackerScore = async ({
  growthTrackerId, scoreType, growthTrackerRawScoreSetAttributes,
  growthTrackerScaledScoreSetAttributes, testDate, baselineScore,
  scoreName,
}) => {
  try {
    const result = await instance.post('/growth_tracker_scores.json', {
      growth_tracker_score: {
        growth_tracker_id: growthTrackerId,
        score_type: scoreType,
        name: scoreName,
        growth_tracker_raw_score_set_attributes: growthTrackerRawScoreSetAttributes,
        growth_tracker_scaled_score_set_attributes: growthTrackerScaledScoreSetAttributes,
        test_date: testDate,
      },
      baseline_score: baselineScore,
    });
    if (result.status === 200) {
      return result.data;
    }
  } catch (error) {
    return { error };
  }
};

export const updateGrowthTrackerScore = async ({
  growthTrackerId, scoreType, growthTrackerRawScoreSetAttributes,
  growthTrackerScaledScoreSetAttributes, testDate, scoreId, baselineScore,
  scoreName,
}) => {
  try {
    const result = await instance.patch(`/growth_tracker_scores/${scoreId}.json`, {
      growth_tracker_score: {
        growth_tracker_id: growthTrackerId,
        score_type: scoreType,
        name: scoreName,
        growth_tracker_raw_score_set_attributes: growthTrackerRawScoreSetAttributes,
        growth_tracker_scaled_score_set_attributes: growthTrackerScaledScoreSetAttributes,
        test_date: testDate,
      },
      baseline_score: baselineScore,
    });
    if (result.status === 200) {
      return result.data;
    }
  } catch (error) {
    return { error };
  }
};

export const createGrowthTrackerBaselineScore = async ({ scoreId, scoreType, growthTrackerId }) => {
  try {
    const result = await instance.post('/growth_tracker_baseline_scores.json',
      {
        growth_tracker_baseline_score:
        {
          growth_tracker_id: growthTrackerId,
          score_id: scoreId,
          score_type: scoreType,
        },
      });
    if (result.status === 200) {
      return result.data;
    }
  } catch (error) {
    return { error };
  }
};

export const deleteGrowthTrackerScore = async ({ scoreId }) => {
  try {
    const result = await instance.delete(`/growth_tracker_scores/${scoreId}/delete.json`, {});
    if (result.status === 200) {
      return result.data;
    }
  } catch (error) {
    return { error };
  }
};
