import { instance, HOST } from '../../config/api';

const outstandingBalanceUrl = '/payments/outstanding_balance.json';
const accountStatementForCustomerUrl = (customerId, page) => `/customers/${customerId}/account_statement.json?page=${page}`;
export const pdfAccountStatementForCustomerUrl = customerId => `${HOST}/api/customers/${customerId}/account_statement.pdf`;

export const FetchOutstandingBalance = async () => {
  try {
    const result = await instance.get(outstandingBalanceUrl, {});
    if (result.status === 200) {
      return result.data;
    }
    return { error: 'unknown error' };
  } catch (error) {
    return { error: error.message };
  }
};

export const FetchAccountStatementForStudent = async ({ id, page }) => {
  try {
    const result = await instance.get(accountStatementForCustomerUrl(id, page), {});
    if (result.status === 200) {
      return result.data;
    }
    return { error: 'unknown error' };
  } catch (error) {
    return { error: error.message };
  }
};
