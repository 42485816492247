import React from 'react';
import {
  Button, Divider, Grid,
} from 'semantic-ui-react';

import { Route, Switch } from 'react-router';
import ContentLayout from '../common/contentLayout';
import StudentPage from './studentPage';
import ListStudents from './listStudents';


function LandingPage() {
  return (
    <ContentLayout>
      <Grid>
        <Grid.Column width={4}>
          <Button id="sign-out" primary compact as="a" href="/accounts/logout">
            Sign Out
          </Button>
        </Grid.Column>
      </Grid>
      <Divider clearing />
      <Switch>
        <Route path="/growth_trackers/:studentId" component={StudentPage} />
        <Route component={ListStudents} />
      </Switch>
    </ContentLayout>
  );
}

export default LandingPage;
