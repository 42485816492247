import React from 'react';

import { Divider, Button } from 'semantic-ui-react';

import { SubmitCurrentStep, SubmitRegion } from './api';

import { BillingPage } from '../billing';
import { Welcome, RegistrationComplete } from '../messages';
import { BillingCancellationForOnboarding } from './policies';
import { TutorshipsForAllStudents } from './tutorshipsForStudents';
import UpdatePassword from '../accounts/password';
import { PersonalProfile } from '../contactInfo/personalProfile';
import { AllStudentProfiles } from './allStudentProfiles';
import { UpdateRegion } from './region';

export const onboardingSteps = [
  {
    index: 1,
    iconName: 'flag',
    title: 'Select Region',
    alignment: 'center',
    verticalAlignment: 'middle',
    step_name: 'set_region',
    renderItem: ({ nextStep }) => (
      <UpdateRegion
        onSuccess={async (region) => {
          await SubmitRegion(region);
          nextStep();
        }}
      />
    ),
  },
  {
    index: 2,
    iconName: 'key',
    title: 'Update Password',
    alignment: 'center',
    verticalAlignment: 'middle',
    step_name: 'set_password',
    renderItem: ({ resetToken }) => (
      <UpdatePassword title="Set your password" resetToken={resetToken} />
    ),
  },
  {
    index: 3,
    iconName: 'info',
    title: 'Summary',
    alignment: 'center',
    verticalAlignment: 'middle',
    step_name: 'summary',
    renderItem: ({ reload }) => (
      <>
        <Welcome />
        <Divider hidden />
        <Button
          primary
          onClick={async () => {
            await SubmitCurrentStep('summary');
            reload();
          }}
        >
          Let's Go
        </Button>
      </>
    ),
  },
  {
    index: 4,
    iconName: 'users',
    title: 'Tutors',
    alignment: 'center',
    verticalAlignment: 'middle',
    step_name: 'tutors',
    renderItem: ({ reload }) => (
      <>
        <TutorshipsForAllStudents />
        <Divider hidden />
        <Button
          primary
          onClick={async () => {
            await SubmitCurrentStep('tutors');
            reload();
          }}
        >
          Looks Good
        </Button>
      </>
    ),
  },
  {
    index: 5,
    iconName: 'shield alternate',
    title: 'Policies',
    alignment: 'center',
    verticalAlignment: 'top',
    step_name: 'policy',
    renderItem: ({ reload }) => (
      <>
        <BillingCancellationForOnboarding
          onClick={async () => {
            await SubmitCurrentStep('policy');
            reload();
          }}
        />
      </>
    ),
  },
  {
    index: 6,
    iconName: 'address card',
    title: 'Contact Details',
    alignment: 'justified',
    verticalAlignment: 'middle',
    step_name: 'contact_details',
    renderItem: ({ reload }) => (
      <PersonalProfile
        isOnboardingStep
        onSuccess={async () => {
          await SubmitCurrentStep('contact_details');
          reload();
        }}
      />
    ),
  },
  {
    index: 7,
    iconName: 'user',
    title: 'Student Details',
    alignment: 'justified',
    verticalAlignment: 'middle',
    step_name: 'student_details',
    renderItem: ({ reload }) => (
      <>
        <AllStudentProfiles
          onSuccess={async () => {
            await SubmitCurrentStep('student_details');
            reload();
          }}
        />
      </>
    ),
  },
  {
    index: 8,
    iconName: 'money bill alternate',
    title: 'Payment Option',
    alignment: 'justified',
    verticalAlignment: 'middle',
    step_name: 'payment_option',
    renderItem: ({ reload }) => (
      <>
        <BillingPage
          onSuccess={async () => {
            await SubmitCurrentStep('payment_option');
            reload();
          }}
        />
      </>
    ),
  },
  {
    index: 9,
    iconName: 'trophy',
    title: 'Complete',
    alignment: 'center',
    verticalAlignment: 'middle',
    step_name: 'onboarding_completed',
    renderItem: () => <RegistrationComplete creditCardIssue />,
  },
];
