import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Select, Form, Message,
} from 'semantic-ui-react';

import { withTutorshipUpdateContext } from './tutorshipUpdateContext';
import { withAsync } from '../common/withAsync';
import { createGrowthTracker } from './api';
import { TestShape } from './shapes';


const GrowthTrackerForm = ({
  data, testTypes, invoke, tutorshipId, updateTutorshipWithMessage,
}) => {
  const [testId, setTestId] = useState(-1);
  const [errorMessage, setErrorMessage] = useState('');

  const options = testTypes.map(testType => ({
    key: testType.id,
    text: testType.name,
    value: testType.id,
  }));

  const handleChange = (event, { value }) => {
    setTestId(value);
  };

  useEffect(
    () => {
      if (data && data.success) {
        updateTutorshipWithMessage('Growth tracker updated successfully.');
      }
    },
    [data, updateTutorshipWithMessage],
  );

  const validate = () => {
    const messages = [];
    if (testId === -1) {
      messages.push('Test type cannot be empty');
    }
    return messages;
  };

  const submitAfterValidation = () => {
    const messages = validate();
    if (messages.length > 0) {
      setErrorMessage(messages.join(', '));
    } else {
      setErrorMessage('');
      invoke({ testId, tutorshipId });
    }
  };

  return (
    <Form
      error={errorMessage !== ''}
    >
      {errorMessage && <Message error visible>{errorMessage}</Message>}
      <Form.Field>
        <Form.Field
          control={Select}
          label="Choose a test"
          options={options}
          placeholder="Test Name"
          onChange={handleChange}
        />
      </Form.Field>
      <Button
        onClick={submitAfterValidation}
        type="submit"
      >
        Submit
      </Button>
    </Form>

  );
};

GrowthTrackerForm.defaultProps = {
  data: {},
};

GrowthTrackerForm.propTypes = {
  data: PropTypes.shape({
    success: PropTypes.string,
    error: PropTypes.string,
  }),
  testTypes: PropTypes.arrayOf(PropTypes.shape(TestShape)).isRequired,
  invoke: PropTypes.func.isRequired,
  tutorshipId: PropTypes.string.isRequired,
  updateTutorshipWithMessage: PropTypes.func.isRequired,
};

export default withTutorshipUpdateContext(withAsync(GrowthTrackerForm, createGrowthTracker, false));
