import React from 'react';
import PropTypes from 'prop-types';

import { Message } from 'semantic-ui-react';

export class FlashMessage extends React.Component {
  render() {
    const { type, message } = this.props;
    return (
      <Message visible error={type === 'alert'} info={type === 'notice'}>
        {message}
      </Message>
    );
  }
}

FlashMessage.propTypes = {
  type: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};
