import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Divider, Header, Segment, Loader, Container, Pagination,
} from 'semantic-ui-react';

export const PaginatedContent = ({
  loading, children, totalPages, fetchItems, noRowsContent,
}) => {
  const [page, updatePage] = useState(1);

  useEffect(
    () => {
      fetchItems({ page });
    },
    [fetchItems, page],
  );

  const handlePaginationChange = (e, { activePage }) => updatePage(activePage);

  if (loading) {
    return (
      <Segment>
        <Loader active />
      </Segment>
    );
  }

  if (totalPages === 0) {
    return (
      <>
        <Header align="center" as="h4">
          {noRowsContent}
        </Header>
        <Divider />
      </>
    );
  }

  return (
    <>
      {children}
      {totalPages > 1 && (
        <Container textAlign="center">
          <Pagination
            siblingRange={2}
            boundaryRange={1}
            pointing
            secondary
            activePage={page}
            totalPages={totalPages}
            onPageChange={handlePaginationChange}
            size="mini"
          />
        </Container>
      )}
    </>
  );
};

PaginatedContent.defaultProps = {
  totalPages: 0,
};

PaginatedContent.propTypes = {
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool.isRequired,
  totalPages: PropTypes.number,
  fetchItems: PropTypes.func.isRequired,
};
