import React from 'react';
import { withRouter, matchPath } from 'react-router';
import PropTypes from 'prop-types';

import { StudentSummary } from './studentSummary';
import { RenderTabbedViews } from '../tabbedViews';

function StudentTabs({ students, location: { pathname }, history }) {
  const match = matchPath(pathname, {
    path: '/dashboard/students/:id',
    exact: true,
    strict: false,
  });
  const studentIdFromUrl = match ? match.params.id : 0;

  const indexOfSelectedStudent = students.map(student => `${student.id}`).indexOf(studentIdFromUrl);
  if (indexOfSelectedStudent === -1 && students.length > 0) {
    history.push(`/dashboard/students/${students[0].id}`);
    return <span />;
  }

  const menuItems = students.map(student => ({
    name: student.name,
    isLink: true,
    link: `/dashboard/students/${student.id}`,
    element: <StudentSummary {...student} />,
  }));

  return <RenderTabbedViews menuItems={menuItems} selectedTabIndex={indexOfSelectedStudent} />;
}

StudentTabs.defaultProps = {
  students: [],
};

StudentTabs.propTypes = {
  students: PropTypes.arrayOf(PropTypes.object),
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  history: PropTypes.object.isRequired,
};


export const StudentTabsWithRouter = withRouter(StudentTabs);
