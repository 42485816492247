import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  Form,
  Radio,
} from 'semantic-ui-react';
import { ScoreShape } from '../shapes';
import { MOCK_TEST_TYPE, OFFICIAL_TEST_TYPE } from '../constants';

const ScoreType = ({
  updateScore, existingScore, editMode,
}) => {
  const [scoreType, setScoreType] = useState(MOCK_TEST_TYPE);

  useEffect(() => {
    updateScore({ scoreType });
  }, [scoreType, updateScore]);


  useEffect(() => {
    if (!editMode) {
      return;
    }
    setScoreType(existingScore.score_type);
  }, [editMode, existingScore]);

  const handleScoreTypeChange = (e, { value }) => {
    setScoreType(value);
  };

  return (
    <Form.Group key="score_type" unstackable>
      <Form.Field>
        <label>Score type</label>
        <Form.Group key="score_type" unstackable inline>
          <Form.Field
            control={Radio}
            label="Mock Test"
            value={MOCK_TEST_TYPE}
            checked={scoreType === MOCK_TEST_TYPE}
            onChange={handleScoreTypeChange}
          />
          <Form.Field
            control={Radio}
            label="Official Test"
            value={OFFICIAL_TEST_TYPE}
            checked={scoreType === OFFICIAL_TEST_TYPE}
            onChange={handleScoreTypeChange}
          />
        </Form.Group>
      </Form.Field>
    </Form.Group>
  );
};

ScoreType.defaultProps = {
  editMode: false,
  existingScore: {},
};

ScoreType.propTypes = {
  editMode: PropTypes.bool,
  existingScore: PropTypes.shape(ScoreShape),
  updateScore: PropTypes.func.isRequired,
};

export default ScoreType;
