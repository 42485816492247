import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Grid,
  Header,
} from 'semantic-ui-react';

import { ScoreShape, TestShape } from '../shapes';

const RawScores = ({
  test, updateScore, existingScore, editMode,
}) => {
  const [rawSubscores, setRawSubscores] = useState([]);

  useEffect(() => {
    const getRawSubcoreAttributes = () => {
      if (!editMode) {
        return rawSubscores.map(subscore => ({
          score: subscore.val,
          growth_tracker_test_subscore_id: subscore.id,
        }));
      }

      return rawSubscores.map((subscore) => {
        const scoreObj = existingScore
          .raw_score_set.subscores
          .filter(sub => sub.growth_tracker_test_subscore.id === subscore.id)[0];
        if (scoreObj) {
          return {
            id: scoreObj.id,
            score: subscore.val,
            growth_tracker_test_subscore_id: subscore.id,
          };
        }

        return {
          score: subscore.val,
          growth_tracker_test_subscore_id: subscore.id,
        };
      });
    };

    if (editMode) {
      updateScore({
        growthTrackerRawScoreSetAttributes: {
          id: existingScore.raw_score_set.id,
          growth_tracker_subscores_attributes: getRawSubcoreAttributes(),
        },
      });
    } else {
      updateScore({
        growthTrackerRawScoreSetAttributes: {
          growth_tracker_subscores_attributes: getRawSubcoreAttributes(),
        },
      });
    }
  }, [updateScore, editMode, rawSubscores, existingScore]);

  useEffect(() => {
    if (!editMode) {
      return;
    }

    const rawSubscoresObj = existingScore
      .raw_score_set
      .subscores
      .map(subscore => ({ id: subscore.growth_tracker_test_subscore.id, val: subscore.score }));
    setRawSubscores(rawSubscoresObj);
  }, [editMode, existingScore]);


  const addOrUpdateRawScore = (subscoreId, value) => {
    const scoresWithoutCurrentSubscore = rawSubscores.filter(sub => sub.id !== subscoreId);
    setRawSubscores([...scoresWithoutCurrentSubscore, { id: subscoreId, val: value }]);
  };

  return (
    <Grid.Column>
      <Header as="h4">Raw Scores</Header>
      {test.subscores.map((subscore) => {
        const score = rawSubscores.filter(sub => sub.id === subscore.id)[0];
        const scoreVal = score ? score.val : '';
        return (
          <Form.Group key={`raw-subscore${subscore.id}`} unstackable>
            <Form.Field>
              <label>{subscore.name}</label>
              <Form.Input
                value={scoreVal}
                onChange={(e, { value }) => {
                  addOrUpdateRawScore(subscore.id, value);
                }}
                placeholder="Enter score"
              />
            </Form.Field>
          </Form.Group>
        );
      })}
    </Grid.Column>
  );
};

RawScores.defaultProps = {
  editMode: false,
  existingScore: {},
};

RawScores.propTypes = {
  test: PropTypes.shape(TestShape).isRequired,
  editMode: PropTypes.bool,
  existingScore: PropTypes.shape(ScoreShape),
  updateScore: PropTypes.func.isRequired,
};

export default RawScores;
