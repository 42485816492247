import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Dimmer, Message, Loader } from 'semantic-ui-react';

import { TutorshipsTable } from './table';
import { RenderTabbedViews } from '../tabbedViews';
import { FetchTutorshipsForStudent } from './api';

const INACTIVE_TUTORSHIP_STATUS = 'inactive';
const ACTIVE_TUTORSHIP_STATUS = 'active';

export function AllTutorships({ studentId }) {
  const [tutorships, setTutorships] = useState([]);
  const [currentStudentId, setCurrentStudentId] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(
    () => {
      setCurrentStudentId(studentId);
      setTutorships([]);
      setIsLoading(false);
      setError('');
    },
    [studentId],
  );

  const fetchTutorships = async (id) => {
    setIsLoading(true);
    const response = await FetchTutorshipsForStudent({ id });
    if (response && response.error) {
      setError(response.error);
    } else {
      setTutorships(response);
      setError('');
    }
    setIsLoading(false);
  };

  useEffect(
    () => {
      if (currentStudentId !== 0) {
        fetchTutorships(currentStudentId);
      }
    },
    [currentStudentId],
  );

  if (isLoading) {
    return (
      <Dimmer active inverted>
        <Loader>Loading</Loader>
      </Dimmer>
    );
  }
  if (error) {
    return <Message visible error content={error} />;
  }

  const tutorshipTabs = [];
  tutorshipTabs.push({
    name: 'Active Tutors',
    element: (
      <TutorshipsTable
        tutorships={tutorships.filter(tutorship => tutorship.status === ACTIVE_TUTORSHIP_STATUS)}
        nonePresentMessage="No Active Tutors on file"
      />
    ),
  });

  const inactiveTutorships = tutorships.filter(
    tutorship => tutorship.status === INACTIVE_TUTORSHIP_STATUS,
  );
  if (inactiveTutorships.length !== 0) {
    tutorshipTabs.push({
      name: 'Inactive Tutors',
      element: (
        <TutorshipsTable
          tutorships={inactiveTutorships}
          nonePresentMessage="No Inactive Tutors on file"
        />
      ),
    });
  }

  return (
    <RenderTabbedViews menuItems={tutorshipTabs} skipTabsIfOnlyOneItem showHeadingIfOnlyOneItem />
  );
}

AllTutorships.propTypes = {
  studentId: PropTypes.number.isRequired,
};
