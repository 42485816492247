import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Accordion, Icon, Message } from 'semantic-ui-react';
import GrowthTrackerContainer from '../tutorship/growthTrackerContainer';
import TutorshipUpdateContext from '../tutorship/tutorshipUpdateContext';
import { studentType } from './types';
import { tutorshipShape } from '../tutorship/shapes';

function Tutorships({ tutorships, student, updateTutorshipWithMessage }) {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleClick = (e, titleProps) => {
    setActiveIndex((currentIndex) => {
      const { index } = titleProps;
      return currentIndex === index ? -1 : index;
    });
  };

  if (!tutorships || tutorships.length === 0) {
    return <Message>No tutorships were found for the student</Message>;
  }

  return (
    <Accordion fluid styled>
      {tutorships.map((tutorship, index) => (
        <React.Fragment>
          <Accordion.Title
            active={activeIndex === index}
            index={index}
            onClick={handleClick}
          >
            <Icon name="dropdown" />
            {tutorship.tutor.name}
            &nbsp;
            {tutorship.subject_names && `(Subject tutored: ${tutorship.subject_names})`}
          </Accordion.Title>
          <Accordion.Content active={activeIndex === index}>
            <TutorshipUpdateContext.Provider
              value={{
                updateTutorshipWithMessage,
                tutorshipId: tutorship.id,
                customerId: student.id,
                showPastScores: true,
                pastResults: student.past_results,
                zincResults: student.zinc_results,
              }}
            >
              <GrowthTrackerContainer
                tutorship={tutorship}
                tutorshipId={tutorship.id}
                notification=""
              />
            </TutorshipUpdateContext.Provider>
          </Accordion.Content>
        </React.Fragment>
      ))}
    </Accordion>
  );
}

Tutorships.defaultProps = {
  tutorships: [],
};

Tutorships.propTypes = {
  student: studentType.isRequired,
  updateTutorshipWithMessage: PropTypes.func.isRequired,
  tutorships: PropTypes.arrayOf(PropTypes.shape(tutorshipShape)),
};

export default Tutorships;
