import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import moment from 'moment';

import { deleteGrowthTrackerScore } from './api';
import { withAsync } from '../common/withAsync';
import { withTutorshipUpdateContext } from './tutorshipUpdateContext';
import { ScoreShape, TestShape, ZincScoreShape } from './shapes';
import ScoreRow from './scoreRow';
import ZincScoreRow from './zincScoreRow';
import { ZINC_SCORE_TYPE } from './constants';

const ScoresBody = ({
  test, scores, growthTrackerId, invoke, data, updateTutorshipWithMessage, baselineScoreSection,
}) => {
  useEffect(
    () => {
      if (data && data.success) {
        updateTutorshipWithMessage('Score deleted successfully');
      }
    },
    [data, updateTutorshipWithMessage],
  );

  const deleteScore = (scoreId) => {
    invoke({ scoreId });
  };

  const sortedScores = scores.sort((a, b) => moment(b.test_date, 'YYYY-MM-DD')
    - moment(a.test_date, 'YYYY-MM-DD'));

  return (
    <Table.Body>
      {sortedScores.map((score) => {
        if (score.type === ZINC_SCORE_TYPE) {
          return (
            <ZincScoreRow
              score={score}
              test={test}
              growthTrackerId={growthTrackerId}
              baselineScoreSection={baselineScoreSection}
              key={score.id}
            />
          );
        }

        return (
          <ScoreRow
            score={score}
            test={test}
            growthTrackerId={growthTrackerId}
            deleteScore={deleteScore}
            baselineScoreSection={baselineScoreSection}
            key={score.id}
          />
        );
      })}
    </Table.Body>
  );
};


ScoresBody.defaultProps = {
  data: {},
};

ScoresBody.propTypes = {
  data: PropTypes.shape({
    success: PropTypes.string,
    error: PropTypes.string,
  }),
  test: PropTypes.shape(TestShape).isRequired,
  scores: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape(ZincScoreShape),
      PropTypes.shape(ScoreShape),
    ]),
  ).isRequired,
  invoke: PropTypes.func.isRequired,
  growthTrackerId: PropTypes.number.isRequired,
  updateTutorshipWithMessage: PropTypes.func.isRequired,
  baselineScoreSection: PropTypes.bool.isRequired,
};

export default withTutorshipUpdateContext(withAsync(ScoresBody, deleteGrowthTrackerScore, false));
