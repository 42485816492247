import React, { useState, useEffect } from 'react';

import { Table } from 'semantic-ui-react';

import { EditForm } from './editForm';
import { EditCreditCardApi } from './api';

import { withAsync } from '../common/withAsync';

export function EditCardComponent({
  cardId, cancelEdit, data, invoke, changeSuccessful,
}) {
  const [cardData, setCardData] = useState({});

  useEffect(() => {
    setCardData(data);
  }, [data]);

  useEffect(
    () => {
      if (cardId) {
        invoke({ cardId });
      }
    },
    [cardId, invoke],
  );

  if (Object.keys(cardData).length === 0) {
    return <></>;
  }

  return (
    <Table.Row>
      <Table.Cell colSpan="4">
        <EditForm
          cardId={cardId}
          name={`${cardData.first_name} ${cardData.last_name}`}
          cardNumber={cardData.card_number}
          address={cardData.address}
          city={cardData.city}
          state={cardData.state}
          country={cardData.country}
          zip={cardData.zip}
          cvvNumber={cardData.cvv_number}
          close={cancelEdit}
          changeSuccessful={changeSuccessful}
        />
      </Table.Cell>
    </Table.Row>
  );
}

EditCardComponent.defaultProps = {
  data: {},
};

export const EditCard = withAsync(EditCardComponent, EditCreditCardApi, false);
