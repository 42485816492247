import React from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';

import { RenderTabbedViews } from '../tabbedViews';
import { PersonalProfile } from './personalProfile';
import { StudentProfiles } from './studentsProfile';

const menuItems = [
  {
    name: 'Update Personal Information',
    element: <PersonalProfile />,
    isLink: true,
    link: '/dashboard/contact-info/personal',
  },
  {
    name: 'Update Student Information',
    element: <StudentProfiles />,
    isLink: true,
    link: '/dashboard/contact-info/students',
  },
];

export function ContactInfoComponent({ location: { pathname } }) {
  const selectedTabIndex = menuItems.map(item => item.link).indexOf(pathname);
  return (
    <RenderTabbedViews
      menuItems={menuItems}
      widths={menuItems.length}
      selectedTabIndex={selectedTabIndex === -1 ? 0 : selectedTabIndex}
    />
  );
}

ContactInfoComponent.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export const ContactInfo = withRouter(ContactInfoComponent);
