import React from 'react';
import PropTypes from 'prop-types';
import { Header } from 'semantic-ui-react';

import { GrowthTrackerShape } from './shapes';
import NewGrowthTracker from './newGrowthTracker';
import ViewGrowthTracker from './viewGrowthTracker';

const GrowthTracker = ({
  growthTrackerAvailable, growthTracker, tutorshipId,
}) => {
  const showForm = !growthTrackerAvailable;

  return (
    <React.Fragment>
      <Header as="h3">Manage Growth Tracker</Header>
      {showForm && (<NewGrowthTracker tutorshipId={tutorshipId} />)}
      <ViewGrowthTracker growthTracker={growthTracker} />
    </React.Fragment>
  );
};

GrowthTracker.defaultProps = {
  growthTracker: {},
  growthTrackerAvailable: false,
};

GrowthTracker.propTypes = {
  growthTracker: PropTypes.shape(GrowthTrackerShape),
  growthTrackerAvailable: PropTypes.bool,
  tutorshipId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};


export default GrowthTracker;
