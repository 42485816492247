import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';

import { withAsync } from '../common/withAsync';
import { markAsConvertedApi } from './api';
import { withLayoutContext } from '../common/layoutContext';
import { studentType } from './types';

function MarkAsConvertedAction({
  invoke, data, student, notify,
}) {
  const history = useHistory();
  const onSuccess = useCallback(() => {
    if (student.next_student_id) {
      notify('Student has been converted successfully. You are viewing the next student.');
      history.push(`/growth_trackers/${student.next_student_id}`);
    } else {
      notify('All students have been converted successfully.');
    }
  },
  [history, notify, student.next_student_id]);

  const markAsConverted = () => {
    invoke({ studentId: student.id });
  };

  useEffect(() => {
    if (data && data.success) {
      onSuccess();
    }
  }, [data, onSuccess]);

  if (student.conversion_status === 'converted') {
    return null;
  }

  return (
    <React.Fragment>
      <Button primary compact id="mark-as-converted" onClick={markAsConverted}>Mark as converted student</Button>
      <br />
      <br />
    </React.Fragment>
  );
}

MarkAsConvertedAction.defaultProps = {
  data: {},
};

MarkAsConvertedAction.propTypes = {
  student: studentType.isRequired,
  data: PropTypes.shape({ success: PropTypes.bool }),
  invoke: PropTypes.func.isRequired,
  notify: PropTypes.func.isRequired,
};

export default withLayoutContext(
  withAsync(MarkAsConvertedAction, markAsConvertedApi, false),
);
