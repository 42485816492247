import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'semantic-ui-react';

import { withAsync } from '../common/withAsync';
import { fetchGrowthTrackerTests } from './api';
import AddScoreFormContainer from './addScoreFormContainer';
import { TestShape } from './shapes';

const AddScoreModal = ({ testId, data, growthTrackerId }) => {
  const [tests, setTests] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setTests(data);
  }, [data]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  if (!data || data.length === 0) {
    return <React.Fragment />;
  }
  const test = tests.filter(testObj => testObj.id === testId)[0];

  return (
    <Modal
      trigger={<Button onClick={handleOpen} color="green">Add score</Button>}
      open={open}
      onClose={handleClose}
      closeOnDimmerClick={false}
    >
      <Modal.Header>Add test score</Modal.Header>
      <Modal.Content>
        <AddScoreFormContainer
          closeModal={handleClose}
          test={test}
          growthTrackerId={growthTrackerId}
        />
      </Modal.Content>
    </Modal>
  );
};

AddScoreModal.defaultProps = {
  data: [],
};

AddScoreModal.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape(TestShape)),
  testId: PropTypes.number.isRequired,
  growthTrackerId: PropTypes.number.isRequired,
};


export default withAsync(AddScoreModal, fetchGrowthTrackerTests, true);
