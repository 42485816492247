import React from 'react';
import { Message } from 'semantic-ui-react';


export default function Errors({ errors }) {
  if (errors && errors.length > 0) {
    return (
      <Message
        error
        header="There was some errors with your submission"
        list={errors}
      />
    );
  }
  return <></>;
}
