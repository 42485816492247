import React from 'react';
import PropTypes from 'prop-types';

import LandingPage from './landingPage';
import ContentLayout from '../common/contentLayout';

const TutorshipContainer = ({ match: { params: { tutorshipId, customerId } } }) => (
  <ContentLayout>
    <LandingPage tutorshipId={tutorshipId} customerId={customerId} />
  </ContentLayout>
);

TutorshipContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      tutorshipId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      customerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  }).isRequired,
};

export default TutorshipContainer;
