import React, { useState, useEffect, useCallback } from 'react';
import { Segment, Loader, Message } from 'semantic-ui-react';

export function withAsync(WrappedComponent, asyncMethod, loadOnMount) {
  function Wrapper(props) {
    const [data, setData] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const invoke = useCallback(async (params) => {
      setLoading(true);
      setError('');
      const response = await asyncMethod(params);
      if (response && response.error) {
        setError(response.error);
      } else {
        setData(response);
      }
      setLoading(false);
    }, []);

    useEffect(
      () => {
        if (loadOnMount) {
          invoke();
        }
      },
      [invoke],
    );

    return (
      <>
        {loading && (
          <Segment>
            <Loader active />
          </Segment>
        )}
        {error && <Message visible error content={error} />}
        <WrappedComponent {...props} invoke={invoke} data={data} />
      </>
    );
  }

  return Wrapper;
}
