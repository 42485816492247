import React from 'react';
import PropTypes from 'prop-types';
import { Table, Label } from 'semantic-ui-react';

import SubscoreCells from './subscoreCells';
import ScoreActionsDropdown from './scoreActionsDropdown';
import { ScoreShape, TestShape } from './shapes';
import { OFFICIAL_TEST_TYPE, MOCK_TEST_TYPE } from './constants';

const ScoreRow = ({
  test, score, growthTrackerId, deleteScore, baselineScoreSection,
}) => {
  const highlightBaselineScore = !baselineScoreSection && score.baseline;
  const rowSpan = test.show_raw_score ? 2 : 1;
  const isOfficialScore = score.score_type === OFFICIAL_TEST_TYPE && !baselineScoreSection;

  return (
    <React.Fragment>
      <Table.Row key={`raw-score-body-${score.id}`} warning={isOfficialScore}>
        <Table.Cell rowSpan={rowSpan}>
          {highlightBaselineScore
            && (
            <React.Fragment>
              <Label ribbon>Baseline Score</Label>
              <br />
            </React.Fragment>
            )}
          {score.name}
          <br />
          <Label size="mini">Growth Tracker Score</Label>
          <br />
          <Label size="mini">{score.score_type === MOCK_TEST_TYPE ? 'Mock Test' : 'Official Test'}</Label>
        </Table.Cell>
        <Table.Cell rowSpan={rowSpan}>
          {score.test_date}
        </Table.Cell>
        <Table.Cell>Scaled</Table.Cell>
        <SubscoreCells test={test} subscores={score.scaled_score_set.subscores} />
        <ScoreActionsDropdown
          rowSpan={rowSpan}
          test={test}
          growthTrackerId={growthTrackerId}
          deleteScore={deleteScore}
          score={score}
          baselineScoreSection={baselineScoreSection}
        />
      </Table.Row>
      {test.show_raw_score && (
      <Table.Row key={`scaled-score-body-${score.id}`}>
        <Table.Cell>Raw</Table.Cell>
        <SubscoreCells test={test} subscores={score.raw_score_set.subscores} />
      </Table.Row>
      )}
    </React.Fragment>
  );
};


ScoreRow.propTypes = {
  growthTrackerId: PropTypes.number.isRequired,
  test: PropTypes.shape(TestShape).isRequired,
  score: PropTypes.shape(ScoreShape).isRequired,
  deleteScore: PropTypes.func.isRequired,
  baselineScoreSection: PropTypes.bool.isRequired,
};

export default ScoreRow;
