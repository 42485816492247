import React from 'react';

const LayoutContext = React.createContext({});

export function withLayoutContext(WrappedComponent) {
  function Wrapper(props) {
    return (
      <LayoutContext.Consumer>
        {({ notify }) => (
          <WrappedComponent
            {...props}
            notify={notify}
          />
        )}
      </LayoutContext.Consumer>
    );
  }

  return Wrapper;
}

export default LayoutContext;
