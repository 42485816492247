import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';

import { TestShape } from './shapes';

const ScoresHeader = ({
  test, baselineScoreSection,
}) => (
  <Table.Header>
    <Table.Row>
      <Table.HeaderCell width="2">
        Name
      </Table.HeaderCell>
      <Table.HeaderCell>
        Test Date
      </Table.HeaderCell>
      <Table.HeaderCell>Score Type</Table.HeaderCell>
      {test.subscores.map(subscore => (
        <Table.HeaderCell key={`table-header-cell-${subscore.id}`}>
          {subscore.name}
        </Table.HeaderCell>
      ))}
      {!baselineScoreSection && <Table.HeaderCell width="2">Actions</Table.HeaderCell>}
    </Table.Row>
  </Table.Header>
);

ScoresHeader.defaultProps = {
  baselineScoreSection: false,
};

ScoresHeader.propTypes = {
  test: PropTypes.shape(TestShape).isRequired,
  baselineScoreSection: PropTypes.bool,
};

export default ScoresHeader;
