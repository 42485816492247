import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import { Message, Table } from 'semantic-ui-react';

import { AccountStatementItem } from './accountStatementItem';
import { PaginatedContent } from '../common/paginatedContent';

import { FetchAccountStatementForStudent } from './api';

export function AccountStatement({ studentId, balance }) {
  const [accountStatement, setAccountStatement] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [currentStudentId, setCurrentStudentId] = useState(0);
  const [currentBalance, setCurrentBalance] = useState(0);
  const [error, setError] = useState('');

  useEffect(
    () => {
      setCurrentStudentId(studentId);
      setCurrentBalance(balance);
      setTotalPages(0);
      setIsLoading(true);
    },
    [studentId, balance],
  );

  const fetchStatement = async ({ id, page }) => {
    setIsLoading(true);
    const response = await FetchAccountStatementForStudent({ id, page });
    if (response && response.error) {
      setError(response.error);
    } else {
      setAccountStatement(response.data);
      setTotalPages(response.pagination.total_pages);
      setError('');
    }
    setIsLoading(false);
  };

  const fetchItems = useCallback(
    ({ page }) => {
      if (currentStudentId !== 0) {
        fetchStatement({ id: currentStudentId, page });
      }
    },
    [currentStudentId],
  );

  return (
    <PaginatedContent
      loading={isLoading}
      fetchItems={fetchItems}
      items={accountStatement}
      totalPages={totalPages}
      noRowsContent="You have no invoices or payments yet"
    >
      {error && (
        <Message visible error attached>
          {error}
        </Message>
      )}
      <Table unstackable striped compact color={currentBalance > 0 ? 'red' : 'green'}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>#</Table.HeaderCell>
            <Table.HeaderCell>Reference ID</Table.HeaderCell>
            <Table.HeaderCell>Description</Table.HeaderCell>
            <Table.HeaderCell>Date</Table.HeaderCell>
            <Table.HeaderCell>Amount</Table.HeaderCell>
            <Table.HeaderCell>Balance</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {accountStatement.map(accountStatementItem => (
            <AccountStatementItem
              key={`${accountStatementItem.type} #${accountStatementItem.number}`}
              {...accountStatementItem}
            />
          ))}
        </Table.Body>
      </Table>
    </PaginatedContent>
  );
}

AccountStatement.propTypes = {
  balance: PropTypes.number.isRequired,
  studentId: PropTypes.number.isRequired,
};
