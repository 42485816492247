import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Table, Header } from 'semantic-ui-react';

import { withAsync } from '../common/withAsync';
import { fetchGrowthTrackerTests } from './api';
import ScoresHeader from './scoresHeader';
import ScoresBody from './scoresBody';
import ScoresEmpty from './scoresEmpty';
import { ScoreShape, TestShape, ZincScoreShape } from './shapes';

const Scores = ({
  testId, scores, data, growthTrackerId, title, baselineScoreSection,
}) => {
  const [tests, setTests] = useState([]);

  useEffect(() => {
    setTests(data);
  }, [data]);

  if (!tests || tests.length === 0 || scores.length === 0) {
    return <React.Fragment />;
  }

  const test = tests.filter(testObj => testObj.id === testId)[0];
  return (
    <React.Fragment>
      <Header as="h4">{title}</Header>
      <Table size="small" compact>
        <ScoresHeader
          test={test}
          baselineScoreSection={baselineScoreSection}
        />
        <ScoresBody
          test={test}
          scores={scores}
          growthTrackerId={growthTrackerId}
          baselineScoreSection={baselineScoreSection}
        />
      </Table>
      <ScoresEmpty scores={scores} title={title} />
    </React.Fragment>
  );
};

Scores.defaultProps = {
  data: [],
  baselineScoreSection: false,
};

Scores.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape(TestShape)),
  scores: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape(ZincScoreShape),
      PropTypes.shape(ScoreShape),
    ]),
  ).isRequired,
  growthTrackerId: PropTypes.number.isRequired,
  testId: PropTypes.number.isRequired,
  baselineScoreSection: PropTypes.bool,
};

export default withAsync(Scores, fetchGrowthTrackerTests, true);
