import React from 'react';

import { Icon, Step, Grid } from 'semantic-ui-react';

export function Progress({ steps, currentStep }) {
  const currentIndex = currentStep.index ? currentStep.index : 0;

  return (
    <Grid.Column computer={3} tablet={3} textAlign="right">
      <Step.Group size="mini" vertical>
        {steps.map(step => (
          <Step
            key={step.title}
            as="menu"
            active={currentIndex === step.index}
            disabled={currentIndex < step.index}
            className={currentIndex > step.index ? 'highlighted' : null}
          >
            <Icon name={step.iconName} color={currentIndex > step.index ? 'green' : null} />
            <Step.Content>
              <Step.Title>{step.title}</Step.Title>
            </Step.Content>
          </Step>
        ))}
      </Step.Group>
    </Grid.Column>
  );
}
