import React from 'react';

const RailsProps = React.createContext('rails-props');

export const getRailsProps = (element) => {
  if (element && element.dataset && element.dataset.rails) {
    return JSON.parse(element.dataset.rails);
  }
  return {};
};

export default RailsProps;
