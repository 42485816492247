import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Divider, Form, Header, Message,
} from 'semantic-ui-react';

import { withAsync } from '../common/withAsync';
import { UpdatePersonProfile } from './api';

export function ProfileFormComponent({
  profile, invoke, data, onSuccess, isOnboardingStep,
}) {
  const [values, setValues] = useState({});
  const [updated, setUpdated] = useState(false);

  const handleChange = (event) => {
    event.persist();
    setValues(formValues => ({ ...formValues, [event.target.name]: event.target.value }));
  };

  useEffect(
    () => {
      setValues({ ...profile });
    },
    [profile],
  );

  useEffect(
    () => {
      if (data && data.success) {
        onSuccess();
        setUpdated(true);
      }
    },
    [data, onSuccess],
  );

  const invalidInput = !(values.first_name && values.last_name && values.best_phone);

  return (
    <>
      {updated && <Message visible positive content="Success! You’ve updated your personal information" />}
      <Form
        className={isOnboardingStep ? '' : 'segment raised'}
        autoComplete="on"
        onSubmit={() => {
          setUpdated(false);
          invoke({ data: { ...values } });
        }}
        key={updated}
      >
        <Header as="h2">
          {isOnboardingStep ? 'Confirm Personal Information' : 'Update Personal Information'}
        </Header>
        <Form.Group widths="equal">
          <Form.Input
            fluid
            required
            autoComplete="given-name"
            label="Parent/Guardian First Name"
            placeholder="First name"
            value={values.first_name}
            name="first_name"
            onChange={handleChange}
          />
          <Form.Input
            fluid
            required
            autoComplete="family-name"
            label="Parent/Guardian Last Name"
            placeholder="Last name"
            name="last_name"
            value={values.last_name}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Input
            fluid
            required
            label="Phone #"
            placeholder="123-456-7890"
            autoComplete="tel"
            name="best_phone"
            value={values.best_phone}
            onChange={handleChange}
          />
          <Form.Input
            fluid
            label="Alternate Phone #"
            placeholder="123-564-8907"
            autoComplete="tel"
            name="alternate_phone"
            value={values.alternate_phone}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group widths="equal">
          <Form.TextArea
            rows={4}
            autoComplete="street-address"
            label="Address"
            placeholder=""
            name="address"
            value={values.address}
            onChange={handleChange}
          />
        </Form.Group>
        <Divider hidden />
        <Button id="update-profile-information" primary disabled={invalidInput} type="submit">
          Confirm
        </Button>
      </Form>
    </>
  );
}

export const ProfileShape = PropTypes.shape({
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  best_phone: PropTypes.string,
  alternate_phone: PropTypes.string,
  address: PropTypes.string,
});

ProfileFormComponent.propTypes = {
  profile: ProfileShape.isRequired,
  invoke: PropTypes.func.isRequired,
  data: PropTypes.shape({ sucess: PropTypes.bool }).isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export const ProfileForm = withAsync(ProfileFormComponent, UpdatePersonProfile, false);
