import React from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Form,
  Message,
  Grid,
  Divider,
} from 'semantic-ui-react';
import { TestShape } from '../shapes';
import ScoreName from './scoreName';
import ScoreType from './scoreType';
import TestDate from './testDate';
import RawScores from './rawScores';
import ScaledScores from './scaledScores';
import BaselineScore from './baselineScore';
import PastScores from './pastScores';

const ScoreForm = ({
  errorMessage, updateScore, existingScore, editMode, closeModal, submitAfterValidation, test,
}) => (
  <Form error={errorMessage !== ''}>
    {errorMessage !== '' && <Message error visible>{errorMessage}</Message>}
    <PastScores />
    <Grid columns={3} divided>
      <Grid.Row>
        <Grid.Column>
          <ScoreName updateScore={updateScore} existingScore={existingScore} editMode={editMode} />
        </Grid.Column>
        <Grid.Column>
          <TestDate updateScore={updateScore} existingScore={existingScore} editMode={editMode} />
        </Grid.Column>
        <Grid.Column>
          <ScoreType updateScore={updateScore} existingScore={existingScore} editMode={editMode} />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <BaselineScore
            updateScore={updateScore}
            existingScore={existingScore}
            editMode={editMode}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
    <Divider clearing />
    <Grid columns={2} divided>
      <Grid.Row>
        <ScaledScores
          test={test}
          updateScore={updateScore}
          existingScore={existingScore}
          editMode={editMode}
        />
        {test.show_raw_score && (
        <RawScores
          test={test}
          updateScore={updateScore}
          existingScore={existingScore}
          editMode={editMode}
        />
        )}
      </Grid.Row>
    </Grid>
    <hr />
    <Button onClick={closeModal}>Cancel</Button>
    <Button onClick={submitAfterValidation} positive>{editMode ? 'Update Score' : 'Add Score'}</Button>
  </Form>
);

ScoreForm.defaultProps = {
  errorMessage: '',
  existingScore: {},
};

ScoreForm.propTypes = {
  errorMessage: PropTypes.string,
  editMode: PropTypes.bool.isRequired,
  updateScore: PropTypes.func.isRequired,
  submitAfterValidation: PropTypes.func.isRequired,
  test: PropTypes.shape(TestShape).isRequired,
  existingScore: PropTypes.shape(TestShape),
  closeModal: PropTypes.func.isRequired,
};

export default ScoreForm;
