import { instance } from '../../config/api';

const contactUsUrl = '/contact_us.json';

export const CreateContactApi = async ({ message }) => {
  try {
    const result = await instance.post(contactUsUrl, { contact_us: { message } });
    if (result.status === 204) {
      return { success: true };
    }
    if (result.status === 200) {
      return result.data;
    }
  } catch (error) {
    return { error: error.message };
  }
};
