import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Container, Grid, Segment } from 'semantic-ui-react';

import { onboardingSteps } from './steps';
import { Header } from './header';
import { Progress } from './progress';
import { FetchCurrentOnboardingStep } from './api';
import RailsProps from '../../util/propsContext';
import { withAsync } from '../common/withAsync';

export function OnboardingComponent({
  data, invoke, resetToken, history,
}) {
  const [currentStepName, setCurrentStepName] = useState(null);

  useEffect(() => {
    if (data) {
      setCurrentStepName(data.current_step);
    }
  }, [data]);

  useEffect(() => {
    if (resetToken) {
      setCurrentStepName('set_region');
    } else {
      invoke();
    }
  }, [invoke, resetToken]);

  useEffect(() => {
    if (currentStepName !== null) {
      history.push(`/onboarding/${currentStepName}`);
    }
  }, [currentStepName, history]);

  const currentStep = onboardingSteps.find(step => step.step_name === currentStepName);
  if (!currentStep) {
    return <></>;
  }

  return (
    <Grid style={{ marginTop: '10px' }}>
      <Header />
      <Grid.Row>
        <Progress currentStep={currentStep} steps={onboardingSteps} />
        <Grid.Column stretched tablet={13} computer={13} largeScreen={12} widescreen={9}>
          <Segment
            className={currentStep.verticalAlignment === 'middle' && 'align-middle-center'}
            padded="very"
          >
            <Container fluid textAlign={currentStep.alignment}>
              {currentStep.renderItem({
                reload: invoke,
                nextStep: () => setCurrentStepName('set_password'),
                resetToken,
              })}
            </Container>
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

const OnboardingComponentWithDeviseProps = props => (
  <RailsProps.Consumer>
    {(railsProps) => {
      const { reset_password_token } = railsProps.devise;
      return <OnboardingComponent {...props} resetToken={reset_password_token} />;
    }}
  </RailsProps.Consumer>
);

export const Onboarding = withAsync(
  withRouter(OnboardingComponentWithDeviseProps),
  FetchCurrentOnboardingStep,
  false,
);
