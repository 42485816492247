import React from 'react';
import { Segment, Header } from 'semantic-ui-react';
import { BillingPolicyContent, CancellationPolicyContent } from '../messages';

export const BillingCancellationPolicy = () => (
  <Segment padded="very" raised>
    <Header as="h2">Billing and Cancellation Policies</Header>
    <BillingPolicyContent />
    <CancellationPolicyContent />
  </Segment>
);
