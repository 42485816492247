import { instance } from '../../config/api';

export const PAYMENTS_URL = '/payments.json';

export const ListAllPayments = async () => {
  try {
    const result = await instance.get(PAYMENTS_URL);
    if (result.status === 200) {
      return result.data;
    }
    return { error: `Unknown error status ${result.status}` };
  } catch (error) {
    return { error: error.message };
  }
};

export const CreateNewPayments = async ({ data }) => {
  try {
    const result = await instance.post(PAYMENTS_URL, data);
    if (result.status === 200) {
      return result.data;
    }
    return { error: `Unknown error status ${result.status}` };
  } catch (error) {
    return { error: error.message };
  }
};
