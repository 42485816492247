import React, { useState, useEffect } from 'react';
import {
  Container, Divider, Segment, Message, Icon,
} from 'semantic-ui-react';

import { PAYMENT_TYPE_CC, PAYMENT_TYPE_WIRE_TRANSFER, PAYMENT_TYPE_CHECK } from '../constants';
import { UpdatePaymentMethodApi } from './api';
import { CreditCardView } from './creditCardView';
import { BackupCreditCard } from './backupCreditCard';
import { BillingMenu, SelectedBillingMenuItem } from './menu';
import { withAsync } from '../common/withAsync';

const ExistingSelectionMessage = ({ paymentType }) => {
  const paymentTypeText = SelectedBillingMenuItem(paymentType);
  if (!paymentTypeText) {
    return <></>;
  }

  return (
    <Message visible positive>
      <Icon name="check" color="green" />
      <strong>
        {paymentTypeText}
        {' '}
      </strong>
      is your active payment method
    </Message>
  );
};

export function PaymentOptionsComponent({
  paymentInfo,
  invoke,
  data,
  onSuccess,
  updatePaymentInfo,
}) {
  const [paymentType, setPaymentType] = useState('');

  useEffect(
    () => {
      if (data && data.success) {
        if (onSuccess) {
          onSuccess();
        } else {
          updatePaymentInfo();
        }
      }
    },
    [data, onSuccess, updatePaymentInfo],
  );

  useEffect(
    () => {
      if (paymentInfo.billing_type !== 'not_set') {
        setPaymentType(paymentInfo.billing_type);
      } else {
        setPaymentType(PAYMENT_TYPE_CC);
      }
    },
    [paymentInfo.billing_type],
  );

  const updatePaymentMethod = async (extraData = {}) => {
    const request = { billing_type: paymentType, ...extraData };
    invoke({ data: request });
  };

  return (
    <>
      <ExistingSelectionMessage paymentType={paymentInfo.billing_type} />
      <Segment raised>
        <BillingMenu setOption={setPaymentType} selectedOption={paymentType} />
        <Container fluid>
          <Divider hidden />
          {paymentType === PAYMENT_TYPE_CC && (
            <CreditCardView
              debitType={paymentInfo.credit_card_debit_type}
              paymentInfo={paymentInfo}
              paymentType={paymentType}
              updatePaymentMethod={updatePaymentMethod}
            />
          )}
          {(paymentType === PAYMENT_TYPE_WIRE_TRANSFER || paymentType === PAYMENT_TYPE_CHECK) && (
            <BackupCreditCard
              key={paymentType}
              paymentInfo={paymentInfo}
              paymentType={paymentType}
              updatePaymentMethod={updatePaymentMethod}
            />
          )}
        </Container>
      </Segment>
    </>
  );
}

export const PaymentOptions = withAsync(PaymentOptionsComponent, UpdatePaymentMethodApi, false);
