import React from 'react';
import { Container, Divider, Grid } from 'semantic-ui-react';

import { HorizontallyAlignedLogo } from '../logo';
import Errors from './errors';
import { RenderTabbedViews } from '../tabbedViews';

import UpdatePassword from './password';
import SignInPage from './signIn';
import PasswordResetPage from './forgotPassword';
import EditPassword from './editPassword';
import RailsProps from '../../util/propsContext';

const menuItems = [
  {
    name: 'Sign In',
    element: <SignInPage />,
    isLink: true,
    link: '/accounts/login',
  },
  {
    name: 'Forgot Password',
    element: <PasswordResetPage />,
    isLink: true,
    link: '/accounts/password/new',
  },
];

const pickComponent = (resetToken, location) => {
  if (location.pathname === '/accounts/edit' || location.pathname === '/accounts') {
    return <EditPassword />;
  }
  if (resetToken) {
    return <UpdatePassword resetToken={resetToken} />;
  }
  const selectedTabIndex = location.pathname === '/accounts/login' ? 0 : 1;
  return (
    <Grid centered>
      <Grid.Column mobile={12} tablet={12} computer={12} largeScreen={8} widescreen={8}>
        <RenderTabbedViews
          menuItems={menuItems}
          widths={menuItems.length}
          selectedTabIndex={selectedTabIndex}
        />
      </Grid.Column>
    </Grid>
  );
};

export default function Accounts({ location }) {
  return (
    <>
      <RailsProps.Consumer>
        {(railsProps) => {
          const { reset_password_token, errors } = railsProps.devise;
          const component = pickComponent(reset_password_token, location);
          return (
            <Container>
              <HorizontallyAlignedLogo />
              <Divider />
              <Errors errors={errors} />
              {component}
            </Container>
          );
        }}
      </RailsProps.Consumer>
    </>
  );
}
