import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import { Divider, Header, Message } from 'semantic-ui-react';

import { withAsync } from '../common/withAsync';
import { FetchStudentsProfiles } from '../contactInfo/api';
import { StudentProfile, ContactShape } from '../contactInfo/studentProfile';

export function AllStudentProfilesComponent({ data, onSuccess }) {
  const [inCompleteUpdates, setInCompleteUpdates] = useState([0]);

  useEffect(
    () => {
      setInCompleteUpdates(data.map(contact => contact.id));
    },
    [data],
  );

  const setConfirmation = useCallback(
    (contactId) => {
      setInCompleteUpdates((currentIncompleteUpdates, props) => {
        const incompleteUpdates = currentIncompleteUpdates.filter(value => value !== contactId);
        if (incompleteUpdates.length === 0) onSuccess();
        return incompleteUpdates;
      });
    },
    [onSuccess],
  );

  return (
    <>
      <Header as="h2">Confirm Student Information</Header>
      <Divider hidden />
      {data.map((contact) => {
        if (inCompleteUpdates.includes(contact.id)) {
          return (
            <span key={contact.id}>
              <StudentProfile contact={contact} onSuccess={setConfirmation} />
              <Divider hidden />
              <Divider hidden />
              <Divider hidden />
            </span>
          );
        }
        return (
          <span key={contact.id}>
            <Message visible info>
              {`Success! You’ve updated the information for ${contact.student.first_name} ${
                contact.student.last_name
              }`}
            </Message>
            <Divider hidden />
          </span>
        );
      })}
    </>
  );
}

AllStudentProfilesComponent.defaultProps = {
  data: [],
};

AllStudentProfilesComponent.propTypes = {
  data: PropTypes.arrayOf(ContactShape),
};

export const AllStudentProfiles = withAsync(
  AllStudentProfilesComponent,
  FetchStudentsProfiles,
  true,
);
