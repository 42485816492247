import React, { useState } from 'react';
import {
  Switch, Route, withRouter, matchPath,
} from 'react-router';
import { Link } from 'react-router-dom';
import {
  Grid, Sticky, Menu, Container,
} from 'semantic-ui-react';

import { MENU_ITEMS } from './menu';
import { HorizontallyAlignedLogo, VerticallyAlignedLogo } from '../logo';

const DashboardStyle = {
  marginTop: '10px',
  padding: '0px 20px',
};

const VerticalTabStyle = {
  color: 'green',
  vertical: true,
  pointing: true,
  secondary: true,
  align: 'right',
};
const HorizontalTabStyle = {
  color: 'green',
  vertical: false,
  pointing: true,
  secondary: true,
  align: 'right',
};

export function Dashboard() {
  const [contextRef, setcontextRef] = useState({});
  const VerticalMenu = () => (
    <Menu {...VerticalTabStyle} style={{ width: 'auto' }}>
      <MenuItemsWithRouter fitted="vertically" orderedMenuItems={MENU_ITEMS} />
    </Menu>
  );

  const HorizontalMenu = () => (
    <Menu className="horizontal-scroll" style={{ overflowX: 'scroll' }} {...HorizontalTabStyle}>
      <MenuItemsWithRouter fitted="horizontally" orderedMenuItems={MENU_ITEMS} />
    </Menu>
  );

  return (
    <Grid style={DashboardStyle}>
      <Grid.Row>
        <Grid.Column
          width={16}
          only="mobile tablet"
          align="center"
          style={{ marginBottom: '10px' }}
        >
          <HorizontallyAlignedLogo />
          <HorizontalMenu />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row stretched>
        <Grid.Column width={3} only="computer">
          <div ref={setcontextRef}>
            <Sticky context={contextRef}>
              <Container align="right">
                <VerticallyAlignedLogo />
              </Container>
              <VerticalMenu />
            </Sticky>
          </div>
        </Grid.Column>

        <Grid.Column
          stretched
          mobile={16}
          computer={12}
          largeScreen={10}
          widescreen={7}
          tablet={16}
        >
          <Switch>
            {MENU_ITEMS.filter(menu => menu.url !== '').map(menu => (
              <Route key={menu.name} path={menu.path} component={menu.element} />
            ))}
            {MENU_ITEMS.filter(menu => menu.url === '').map(menu => (
              <Route key={menu.name} component={menu.element} />
            ))}
          </Switch>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

function MenuItems(props) {
  const menuStyle = { backgroundColor: 'white' };
  const {
    orderedMenuItems,
    fitted,
    location: { pathname },
  } = props;
  return (
    <>
      {orderedMenuItems.map(menuItem => (
        <Menu.Item
          key={menuItem.name}
          name={menuItem.name}
          fitted={fitted}
          style={menuStyle}
          as={Link}
          to={menuItem.url === '' ? '/dashboard' : menuItem.url}
          aria-label={menuItem.name}
          active={
            matchPath(pathname, {
              path: menuItem.path,
              exact: true,
              strict: false,
            }) !== null
          }
        />
      ))}
    </>
  );
}

const MenuItemsWithRouter = withRouter(MenuItems);
