import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  Form,
  Checkbox,
} from 'semantic-ui-react';
import { ScoreShape } from '../shapes';

const BaselineScore = ({
  updateScore, existingScore, editMode,
}) => {
  const [baselineScore, setBaselineScore] = useState(false);
  const [readOnly, setReadOnly] = useState(false);

  useEffect(() => {
    updateScore({ baselineScore });
  }, [baselineScore, updateScore]);


  useEffect(() => {
    if (!editMode) {
      return;
    }

    setReadOnly(existingScore.baseline);
    setBaselineScore(existingScore.baseline);
  }, [editMode, existingScore]);

  const handleBaselineScoreChange = () => {
    setBaselineScore(!baselineScore);
  };

  return (
    <Form.Group key="baseline_score" unstackable>
      <Form.Field>
        <Checkbox
          label="Mark as baseline score"
          readOnly={readOnly}
          checked={baselineScore}
          onChange={handleBaselineScoreChange}
        />
      </Form.Field>
    </Form.Group>
  );
};

BaselineScore.defaultProps = {
  editMode: false,
  existingScore: {},
};

BaselineScore.propTypes = {
  editMode: PropTypes.bool,
  existingScore: PropTypes.shape(ScoreShape),
  updateScore: PropTypes.func.isRequired,
};

export default BaselineScore;
