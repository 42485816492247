import React, { useState, useEffect } from 'react';
import {
  Button, Divider, Form, Grid, Radio, Segment, Message,
} from 'semantic-ui-react';

import { DEBIT_TYPES } from '../constants';
import { withAsync } from '../common/withAsync';
import { CreditCardList } from '../creditCards';
import { ListAllCreditCards } from '../creditCards/api';
import { PendingConfirmationMessage } from './pendingConfirmationMessage';

export function CreditCardViewComponent({
  debitType,
  data,
  invoke,
  paymentInfo,
  paymentType,
  updatePaymentMethod,
}) {
  const [selectedDebitType, setSelectedDebitType] = useState('');
  const [creditCards, setCreditCards] = useState([]);

  const [updateMessage, setUpdateMessage] = useState(false);

  const reloadCreditCards = ({ message }) => {
    setUpdateMessage('');
    if (message) {
      setUpdateMessage(message);
    }
    invoke();
  };

  useEffect(
    () => {
      setCreditCards(data);
    },
    [data],
  );

  useEffect(
    () => {
      setSelectedDebitType(debitType);
    },
    [debitType],
  );
  return (
    <>
      <Segment>
        {updateMessage && <Message visible positive content={updateMessage} />}
        <CreditCardList creditCards={creditCards} reload={reloadCreditCards} hideNewCard/>
      </Segment>

      <Form>
        {DEBIT_TYPES.map(type => (
          <Form.Group key={type.key}>
            <Radio
              label={type.text}
              name="radioGroup"
              value={type.value}
              checked={selectedDebitType === type.value}
              onChange={() => setSelectedDebitType(type.value)}
            />
          </Form.Group>
        ))}
        <PendingConfirmationMessage paymentType={paymentType} paymentInfo={paymentInfo} />
        <Divider hidden />
        <Grid centered>
          <Button
            primary
            disabled={
              creditCards.length === 0
              || selectedDebitType === ''
              || selectedDebitType === 'no_credit_card_debit_type'
            }
            onClick={() => updatePaymentMethod({ credit_card_debit_type: selectedDebitType })}
            id="update-payment-method"
          >
            Confirm
          </Button>
        </Grid>
        <Divider hidden />
      </Form>
    </>
  );
}

CreditCardViewComponent.defaultProps = {
  data: [],
};

export const CreditCardView = withAsync(CreditCardViewComponent, ListAllCreditCards, true);
