import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Header, Table } from 'semantic-ui-react';

import { ReferralShape } from './shapes';

const Referrals = ({ referrals }) => {
  if (referrals.length === 0) {
    return <React.Fragment />;
  }

  return (
    <React.Fragment>
      <br />
      <Divider clearing />
      <Header as="h3">Referrals</Header>
      <Table basic>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Referred By</Table.HeaderCell>
            <Table.HeaderCell>Referral Rate</Table.HeaderCell>
            <Table.HeaderCell>Referral Expiry</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {referrals.map(referral => (
            <Table.Row key={referral.name}>
              <Table.Cell>{referral.name}</Table.Cell>
              <Table.Cell>{`${referral.rate} %`}</Table.Cell>
              <Table.Cell>{referral.expiration_date}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </React.Fragment>
  );
};

Referrals.defaultProps = {
  referrals: [],
};

Referrals.propTypes = {
  referrals: PropTypes.arrayOf(PropTypes.shape(ReferralShape)),
};

export default Referrals;
