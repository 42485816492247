import React from 'react';
import { Divider, Header, Segment } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import { withAsync } from '../common/withAsync';
import { FetchStudentsProfiles } from './api';
import { RenderTabbedViews } from '../tabbedViews';
import { StudentProfile, ContactShape } from './studentProfile';

export function StudentProfilesComponent({ data }) {
  const menuItems = data.map(contact => ({
    name: `${contact.student.first_name} ${contact.student.last_name}`,
    element: <StudentProfile contact={contact} />,
  }));

  return (
    <Segment raised>
      <Header as="h2">Update Student Information</Header>
      <Divider hidden />
      <RenderTabbedViews
        menuItems={menuItems}
        selectedTabIndex={0}
        skipTabsIfOnlyOneItem
        showHeadingIfOnlyOneItem={false}
      />
    </Segment>
  );
}

StudentProfilesComponent.defaultProps = {
  data: [],
};

StudentProfilesComponent.propTypes = {
  data: PropTypes.arrayOf(ContactShape),
};

export const StudentProfiles = withAsync(StudentProfilesComponent, FetchStudentsProfiles, true);
