import { DashboardLanding } from '../landing/index';
import { ContactUs } from '../contactUs';
import { BillingPage } from '../billing/index';
import { Payment as PaymentPage } from '../payment';
import { ContactInfo } from '../contactInfo';
import { SignOutPage } from '../signout';
import { BillingCancellationPolicy } from '../policy';

export const MENU_ITEMS = [
  {
    name: 'Dashboard',
    element: DashboardLanding,
    url: '',
    path: '/dashboard/students/:id',
  },
  {
    name: 'Contact info',
    element: ContactInfo,
    url: '/dashboard/contact-info/personal',
    path: '/dashboard/contact-info/:tab',
  },
  {
    name: 'Billing',
    element: BillingPage,
    url: '/dashboard/billing',
    path: '/dashboard/billing',
  },
  {
    name: 'Payment',
    element: PaymentPage,
    url: '/dashboard/payment',
    path: '/dashboard/payment',
  },
  {
    name: 'Contact us',
    element: ContactUs,
    url: '/dashboard/contactus',
    path: '/dashboard/contactus',
  },
  {
    name: 'Policies',
    element: BillingCancellationPolicy,
    url: '/dashboard/policy',
    path: '/dashboard/policy',
  },
  {
    name: 'Update Password',
    element: SignOutPage,
    url: '/accounts/edit',
    path: '/accounts/edit',
  },
  {
    name: 'Sign out',
    element: SignOutPage,
    url: '/dashboard/signout',
    path: '/dashboard/signout',
  },
];
