import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  DateInput,
} from 'semantic-ui-calendar-react';
import {
  Form,
} from 'semantic-ui-react';

import { ScoreShape } from '../shapes';

const TestDate = ({
  updateScore, existingScore, editMode,
}) => {
  const [testDate, setTestDate] = useState(moment().format('YYYY-MM-DD'));

  useEffect(() => {
    updateScore({ testDate });
  }, [testDate, updateScore]);

  useEffect(() => {
    if (!editMode) {
      return;
    }
    setTestDate(existingScore.test_date);
  }, [editMode, existingScore]);

  const onDateChange = (event, calData) => setTestDate(calData.value);

  return (
    <Form.Group key="test_date" unstackable>
      <Form.Field required>
        <label>Test Date</label>
        <DateInput
          name="test_date"
          placeholder="Test Date"
          value={testDate}
          iconPosition="left"
          onChange={onDateChange}
          closable
          clearable={false}
          pickerWidth="200"
          dateFormat="YYYY-MM-DD"
          initialDate={testDate}
          preserveViewMode={false}
        />
      </Form.Field>
    </Form.Group>
  );
};

TestDate.defaultProps = {
  editMode: false,
  existingScore: {},
};

TestDate.propTypes = {
  editMode: PropTypes.bool,
  existingScore: PropTypes.shape(ScoreShape),
  updateScore: PropTypes.func.isRequired,
};

export default TestDate;
